@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lexend Deca', sans-serif !important;
}

:root{
  --header-color: #0b1d42;
  --header-buttons: #fff;
}

.navbarResponsivo {
  background-color: #FFFFFF;

  /* border-color: #d9d9d9; */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed !important;
  top: 0;
  left:0;
  width: 100%;
z-index: 105;
  border-radius: 0px 0px 10px 10px; 
}

/* Navbar CSS */
.navbar {
  background-color: #FFFFFF;
 
  /* border-color: #d9d9d9; */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed !important;
  top: 0;
  left:50px;
  width: 100%;
z-index: 105;
  border-radius: 10px 0px 0px 10px; 

  transition: left 0.5s ease; 


}

.navbar2 {
  background-color: #D74544;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  top: 0;
  margin-top: 60px;
  width: 100%;
  z-index: 100;
  font-weight: 300;
  font-size: 14px;
  color: white
}

.divPage{
  padding-left: 10vh;
  padding-right: 10vh;
  padding-top: 20vh;
}

.home {
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin-top: 60px; */
  color: #111111;
}
.listPagesMobile {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  overflow: auto;
  height: calc(100vh - 40px);
  flex-direction: column;
  background-color: #dadeeb !important;
  background-image: url("images/Fundo Dashboard Cliente.png");
  background-size: 80%;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 105;
  margin-bottom: 40px;
  transition: padding-left 0.5s ease;
  margin-left: 0;
  transition: margin-left 0.5s ease;
  border-radius: 10px;
}
.listPages {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  overflow: auto;
  height: 100vh;
  flex-direction: column;
  background-color: #dadeeb !important;
  background-image: url("images/Fundo Dashboard Cliente.png");
  background-size: 80%;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 105;
  transition: padding-left 0.5s ease;
  margin-left: 50px;
  transition: margin-left 0.5s ease;
  border-radius: 10px;
}
.listPagesDirectorioPiscinas {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  overflow: auto;
  height: 100vh;
  flex-direction: column;
  background-color: #dadeeb !important;
  background-image: url("images/Fundo Dashboard Cliente.png");
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 105;
 }

.listPagesF {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  padding-top: 10%;
  overflow:auto;
  height: 100%;
  flex-direction: column;

}
.listPages2 {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  margin-left: 5%;
  margin-right: 5%;
  overflow:auto;
  height: 100%;
  flex-direction: column;

}

.myCalendar {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  margin-left: 5%;
  margin-right: 5%;
  overflow:auto;
  height: 100vh;
  flex-direction: column;

}


@media only screen and (max-width: 800px) {
 

  .myCalendar {
    /* height: 100%; */
  
  }

  .listPages4 {
    /* height: 100%; */
  
  }

  .fc .fc-toolbar-title {
    font-size: 10px;
    padding-left:4vh;
  }
  
}

.listPages3{
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
}
.listPages2{
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  background-color: #f7f7f7;
}

.listPages4{
  align-items: center;
  justify-content: center;
  display: flex;
  height: 80vh;
  background-color: #f7f7f7;
}

.priceupdate, .profile{
  margin-top: 7vw;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  display: flex;
}

.logo {
  margin-left: 2rem;
  font-size: 1.5rem;
  background: none;
  color: #7F7F7F;
}

.headerRight{
 position:fixed;
 right: 30px;
 transition: right 0.5s ease;


}
.headerName{
  margin-left: 50px;
}

.headerIcons, .headerIcons:hover{
  font-size: 1.2rem;
  color: #000;

  align-items: center;
}

.headerIcons2, .headerIcons2:hover{
  font-size: 1.3rem;
color: #000;
  
  align-items: center;
}

.headerName, .headerName:hover{
  font-size: 1.2rem;
  margin-right: 2rem;
  background: none;
  color: #343434;
  align-items: center;
  text-decoration: none;
  cursor: default;
}


.nav-menu.active {
  left: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1e354e;
  color: #fff;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: #7F7F7F;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}


/* PÃ¡ginas Inicias */
.bodyLogin {
  height: 100vh;
  width: 100vw;
  margin: 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
}
h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  opacity: 0.85;
}
label {
  font-size: 12.5px;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}

form {
  padding: 40px 40px;
  background: #fefefe;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.form2 {
  padding: 40px 40px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-bottom: 20px;
}
.inputcard{
  width: 30%;
  border-radius: 10px;
  margin-bottom: 1%;
  

}
form h4 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .5);
}
form h4 span {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}
form p {
  line-height: 155%;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
  opacity: 0.65;
  font-weight: 400;
  max-width: 250px;
  margin-bottom: 20px;
  margin-top: 20px;
}
a.discrete {
  color: rgba(0, 0, 0, .4);
  font-size: 14px;
  border-bottom: solid 1px rgba(0, 0, 0, .0);
  padding-bottom: 4px;
  margin-left: auto;
  font-weight: 300;
  transition: all 0.3s ease;
  margin-top: 40px;
}
a.discrete:hover {
  border-bottom: solid 1px rgba(0, 0, 0, .2);
}
.button {
  cursor: pointer;
  -webkit-appearance: none;
  width: auto;
  min-width: 100px;
  border-radius: 24px;
  text-align: center;
  padding: 15px 40px;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  font-weight: 500;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .13);
  border: none;
  transition: all 0.3s ease;
  outline: 0;
}

input {
  font-size: 16px;
  padding: 20px 0px;
  height: 56px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  background: #fff;
  min-width: 280px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
}

input:focus {
  /* border-bottom: solid 1px #b69de6; */
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
}
.floating-label {
  position: relative;
  margin-bottom: 10px;
}
.floating-label label {
  position: absolute;
  top: calc(50% - 7px);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.floating-label input:not(:placeholder-shown) {
  padding: 28px 0px 12px 0px;
}
.floating-label input:not(:placeholder-shown) + label {
  transform: translateY(-10px);
  opacity: 0.7;
}

.session {
  display: block;
  flex-direction: column;
  background-color: #DADFEB !important;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
overflow: hidden;
overscroll-behavior: none;
}


.session2 {
  display: block;
  flex-direction: column;
  background-color: #DADFEB !important;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
overflow: hidden;
overscroll-behavior: none;
}

.session3 {
  display: block;
  flex-direction: column;
  background-color: #DADFEB !important;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
overflow: hidden;
overscroll-behavior: none;
}

@media (min-width: 601px) and (max-width: 1100px) {
  .session {
    background-image: url("images/Fundo Iniciar.png");
    background-size: 100%; /* Imagem maior para tablets */
  }
  .session2{
    background-image: url("images/Fundo Iniciar 2.png");
    background-size: 100%;
  }
}
@media (max-width: 600px) {
  .session {
    background-image: url("images/Fundo_Iniciar_Telemovel.png");
    background-size: 100%; /* Imagem maior para tablets */
  }  .session2{
    background-image: url("images/Fundo_Iniciar_2_Telemovel.png");
    background-size: 100%;
  }
}
@media (min-width: 1100px) {
  .session {
    background-image: url("images/Fundo Iniciar.png");
    background-size: 80%; /* Imagem menor para computadores */
  }
  .session2{
    background-image: url("images/Fundo Iniciar 2.png");
    background-size: 80%;
  }
}

.formLoginPhone2 {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.formLogin {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  border-radius: 10px;
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: flex-start;
  color:#FAFAFA;
 

}

.formLogin:focus-within{
  background-color: rgba(255, 255, 255, 0.4); /* Cor de fundo com mais opacidade */
}

.formLogin .MuiInputBase-root {
  padding: 0px 0px 10px 20px;
  color:#FAFAFA


}

.formLogin .MuiInputBase-input {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8); 
  caret-color: rgba(0, 0, 0, 0.8); 
  outline: none;
  border: none;
  background-color: transparent !important;
 width:200px;
  margin-top: -5px; /* Ajuste a margem superior conforme necessário */


  
}

.MuiInputBase-input:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiInputBase-input:-webkit-autofill:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
 .Mui-error {
  padding-left: 20px !important;
  color:#f04643 !important;
  font-size: 10px !important;
  padding-bottom: 3px !important;
 
}

.formLogin .MuiInputLabel-root {
  padding: 0px  20px 0px 15px;
  line-height:14px; 
  display: flex;
  color:#FAFAFA;
 /* Centralizar verticalmente */
  transition: transform 0.2s ease-out, font-size 0.2s ease-out;
}

.formLogin .MuiInputLabel-shrink {
  display: none;
}

.formLogin .MuiInputLabel-hidden {
  display: none;
}

.formLogin .MuiInput-underline:before,
.formLogin .MuiInput-underline:after {
  display: none;
}

.formLogin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1.5px solid #FFFFFF;
  border-radius: 10px;
}


.formLoginPhone {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  border-radius: 10px;
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: flex-start;
 

}
.PhoneInputCountry {
  margin-bottom: 12px !important;
  margin-left: 10px !important;
}
.formLoginPhone.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl.css-l4u8b9-MuiInputBase-root-MuiInput-root,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl.css-l4u8b9-MuiInputBase-root-MuiInput-root input {
  margin-top: 14px;
}


.formLoginPhone:focus-within{
  background-color: rgba(255, 255, 255, 0.4); /* Cor de fundo com mais opacidade */
}

.formLoginPhone .MuiInputBase-root {
  padding: 0px 0px 10px 20px;
color:#FAFAFA;

}

.formLoginPhone .MuiInputBase-input {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8); 
  caret-color: rgba(0, 0, 0, 0.8); 
  outline: none;
  border: none;
  background-color: transparent !important;
 width:200px;


  
}

.MuiInputBase-input:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiInputBase-input:-webkit-autofill:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
 .Mui-error {
  padding-left: 20px !important;
  color:#f04643 !important;
 
}

.formLoginPhone .MuiInputLabel-root {
  padding: 3px 10px 0px 10px;
  line-height: 14px; 
  display: flex;
  color:#FAFAFA;
 /* Centralizar verticalmente */
  transition: transform 0.2s ease-out, font-size 0.2s ease-out;
}

.formLoginPhone .MuiInputLabel-shrink {
  display: none;
}

.formLoginPhone .MuiInputLabel-hidden {
  display: none;
}

.formLoginPhone .MuiInput-underline:before,
.formLoginPhone .MuiInput-underline:after {
  display: none;
}

.formLoginPhone::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1.5px solid #FFFFFF;
  border-radius: 10px;
}


.left {
  width: 70%;
  height: 100%;
  position: relative;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.recoverPassword{
  margin-bottom: 20px;
  font-size: 11px;
  color: gray;
  cursor: pointer;
  text-decoration: none;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(34, 30, 30);
  opacity: 0.85;
}

form h5 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .5);
}
form h5 span {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

/* Cards Home */
.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction:column;
 
}

.containerrow {
 
  display: flex;
  flex-direction:row;
  width: 100%;
  align-items: center;
  justify-content: center;
 
}


.containerrow1 {
  width: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
 
 
}
.containerrow3 {
  width: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;

 
 
}

.card {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.card0{
background-color: #f57c00;
}

.card1{
  background-color: #8bc34a;
  
}
.card2{
  background-color: #fbc02d;
}

.homeIcon{
  color: white;
  font-size: 2.1vw;
  margin-bottom: 10px;
}

/* CSS DO DIOGO */ 
/* Páginas Inicias */
.bodyLogin2 {
  height: 100vh;
  width: 100vw;
  margin: 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
}
h4 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  opacity: 0.85;
}
label {
  font-size: 12.5px;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}
.log-in{
  justify-content: center;
  flex-direction: column;
    display: flex;
    align-items: center;
   width: 30%;
   /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
  
}

.inicioCol {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;

  background-color: transparent;

  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
}

.aviso-pagamento {
  color: white;
  background-color: rgba(240, 70, 67, 1);
  width: 80%;
  height: 50px;
  text-align: center;
  position: absolute; /* Altera a posição para absolute */
  top: 130px; /* Ajuste a posição vertical conforme necessário */
}

.button {
  cursor: pointer;
  -webkit-appearance: none;
  width: auto;
  min-width: 100px;
  border-radius: 24px;
  text-align: center;
  padding: 15px 40px;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  font-weight: 500;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .13);
  border: none;
  transition: all 0.3s ease;
  outline: 0;
}

input {
  font-size: 16px;
  padding: 20px 0px;
  height: 56px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, .1);
  background: #fff;
  min-width: 280px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
}
input:focus {
  /* border-bottom: solid 1px #b69de6; */
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
}
.floating-label {
  position: relative;
  margin-bottom: 10px;
}
.floating-label label {
  position: absolute;
  top: calc(50% - 7px);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.floating-label input:not(:placeholder-shown) {
  padding: 28px 0px 12px 0px;
}
.floating-label input:not(:placeholder-shown) + label {
  transform: translateY(-10px);
  opacity: 0.7;
}

.left {
  width: 70%;
  height: 100%;
  position: relative;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}



body{
  background-color: #ffffff;
}

/*Basic structure of slider*/
.slider{
height: 100%;
  position:relative;
  top: 0;
  left: 0;
  overflow: hidden;


}

/*Area of images*/
.wrapper{

  display: flex;
  animation: slide 10s linear infinite;
  object-fit: fill;
  
 
}

.fundo{
  max-width: 100%;
  
object-fit: fill;

}
/*Animation activated by keyframes*/
@keyframes slide{
  0%{
    transform: translateX(0);
}
25%{
    transform: translateX(0);
}
30%{
    transform: translateX(-100%);
}
50%{
    transform: translateX(-100%);
}
55%{
    transform: translateX(-200%);
}
75%{
    transform: translateX(-200%);
}
80%{
    transform: translateX(-200%);
}
100%{
    transform: translateX(0);
}
  
}
.left svg {
  height: 40px;
  width: auto;
  margin: 20px;
}

.recoverPassword{
  margin-bottom: 20px;
  font-size: 11px;
  color: gray;
  cursor: pointer;
  text-decoration: none;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(34, 30, 30);
  opacity: 0.85;
}

form h5 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .5);
}
form h5 span {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}

/* Cards Home */
.container {
  height:100vh;
  width: 100vw;
  display: flex;

  align-items: flex-start;
  
}

.card {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.cards{
  color: white;
  font-size: 18px;

}

.cardspool{
  color: #171E24;
  font-size: 13px;
  margin: 5%;
display: none;
}

.cardsdesinscrever{
  color: rgba(240, 70, 67, 0.50);
  font-family: Lexend Deca;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 5%;
display: none;
}

.cardspool2{
  color: white;
  font-size: 13px;
  margin: 5%;

}

.circular{
  background-color: #1fb852;
  border-radius: 50%;
}

.card0{
background-color: #f57c00;
}

.card1{
  background-color: #8bc34a;
  
}
.card2{
  background-color: #fbc02d;
}



.homeIcon{
  color: white;
  font-size: 30px;

}

.homeIcon2{
  color: white;
  font-size: 30px;
  margin-top: 10px;
}

/* Cards Atualização Preços */
.container2 {
  height: 55vh;
  /* width: 50vw; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
  align-items: center; 
   display: flex; 
}

.container3{
  height: 55vh;
  /* width: 50vw; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 150px;
  margin-right: 150px;
  align-items: center; 
   display: flex; 
}

.card4{
  background-color: #96BF49;
  
}
.card5{
  background-color: #1b769c;
}

.card6{
  background-color: #29a851;
}

.card7{
  background-color: #c5a7e5;
}

.card8{
  background-color: #ffc107;
}

.card_ {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
}

.card_d {
  height: 300px;
  width: 300px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
  padding: 25px;
  text-align: center;
  color: #000;
}


.cards_{
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}

/* css Botoes pagina inicial */
.iniciobtn {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  background: #0A56FA;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  overflow: hidden;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
.iniciobtn:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-position: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.iniciobtn2:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-position: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}

.anteriorbtn {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  background: #A5BDF2;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  overflow: hidden;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}


.iniciobtn2 {
  box-sizing: border-box;
  position: relative;
  width: 300px;
  height: 50px;
  background: #0A56FA;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0A56FA;
  overflow: hidden;
  border: 1.5px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  background-image: url('images/BotaoDiretorioPiscinas.svg');
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;

}

  
 



  .userbtn {
      height: 60px;
      width: 200px;
      padding: 1em 3em;
      font-size: 12px;
      letter-spacing: 1.5px;
      font-weight: 500;
      color: #000;
      background-color: #fff;
      border: none;
      border-radius: 40px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
    }
    
    .userbtn:hover {
      background-color: #51c2ff;
      box-shadow: 0px 15px 20px rgb(66 112 158);
      color: #fff;
      transform: translateY(-7px);
    }
    
    .userbtn:active {
      transform: translateY(-1px);
    }


    .desativarbtn {
      height: 80px;
      width: 300px;
        padding: 1em 3em;
        font-size: 12px;
        letter-spacing: 1.5px;
        font-weight: 500;
        color: #000;
        background-color: #fff;
        border: none;
        border-radius: 40px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
      }
      
      .desativarbtn:hover {
        background-color: rgb(100, 5, 129);
        box-shadow: 0px 15px 20px rgb(171, 34, 235);
        color: #fff;
        transform: translateY(-7px);
      }
      
      .desativarbtn:active {
        transform: translateY(-1px);
      }



      .excluirbtn {
        height: 80px;
        width: 300px;
          padding: 1em 3em;
          font-size: 12px;
          letter-spacing: 1.5px;
          font-weight: 500;
          color: #000;
          background-color: #fff;
          border: none;
          border-radius: 40px;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease 0s;
          cursor: pointer;
          outline: none;
        }
        
        .excluirbtn:hover {
          background-color: red;
          box-shadow: 0px 15px 20px rgb(117, 6, 6);
          color: #fff;
          transform: translateY(-7px);
        }
        
        .excluirbtn:active {
          transform: translateY(-1px);
        }

         .pedidosbtn {
        height: 60px;
        width: 200px;
          padding: 1em 3em;
          font-size: 12px;
          letter-spacing: 1.5px;
          font-weight: 500;
          color: #000;
          background-color: #fff;
          border: none;
          border-radius: 40px;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease 0s;
          cursor: pointer;
          outline: none;
        }
        
        .pedidosbtn:hover {
          background-color: #ff9e40;
          box-shadow: 0px 15px 20px rgb(156, 72, 13);
          color: #fff;
          transform: translateY(-7px);
        }
        
        .pedidosbtn:active {
          transform: translateY(-1px);
        }
  




    .iniciobtn3 {
      height: 60px;
      width: 200px;
        padding: 1em 3em;
        font-size: 12px;
        letter-spacing: 1.5px;
        font-weight: 500;
        color: #27345b;
        background-color: #51c2ff;
        border: none;
        border-radius: 40px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
       
      }
      
      .iniciobtn3:hover {
        background-color: #27345b;
        box-shadow: 0px 15px 20px rgb(66 112 158);
        color: #51c2ff;;
        transform: translateY(-7px);
      }
      
      .iniciobtn3:active {
        transform: translateY(-1px);
      }
    
  /* link login  */
  
  .textlinklogin{
  
    padding-bottom: 3%;
    color: grey;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;

    
  }
  
  
  .textlinklogin:hover{
    color:#343434;
    transform: scale(1.04);
    background-size: 104%;
  }

  .textlinklogin2{
  
   
    color: grey;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;

    
  }
  
  
  .textlinklogin2:hover{
    color:#343434
  }
  
  /* form registar */
  .registarform{
    justify-content: center;
    flex-direction: column;
      display: flex;
      align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
    
  }
  

  
    .marcar{
      justify-content: center;
      flex-direction: column;
        display: flex;
        align-items: center;
      width: 100%;
      margin-top: 5%;

    }
  
  .titulo{
    font-size: 20px;
    font-weight: 800 !important;
    margin-bottom: 20px;
  }

  .titulonome{
    font-size: 25px;
    text-align: center;
    margin-top: 40;
   
  }
  
  .subtitulo{
    font-size: 14px;
    font-weight: 300;
    color: "#171E24";
  }
  
  /* regidto btn */
  .regbtn {
    height: 50px;
    width: 130px;
      padding: 1em 3em;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 500;
      color: #000;
      background-color: #fff;
      border: none;
      border-radius: 40px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
     
    }
    
    .regbtn:hover {
      background-color: #51c2ff;
      box-shadow: 0px 15px 20px rgb(66 112 158);
      color: #fff;
      transform: translateY(-7px);
    }
    
    .regbtn:active {
      transform: translateY(-1px);
    }
    
  
    .regbtnfim {
      height: 50px;
      width: 130px;
        padding: 1em 3em;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #000;
        background-color: #fff;
        border: none;
        border-radius: 40px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
       
      }
      
      .regbtnfim:hover {
        background-color: #1fb852;
        box-shadow: 0px 15px 20px rgb(15, 80, 15);
        color: #fff;
        transform: translateY(-7px);
      }
      
      .regbtnfim:active {
        transform: translateY(-1px);
      }
  
  
      .regbtnfim:disabled{
        background-color: #7F7F7F;
        box-shadow: 0px 15px 20px rgb(92, 94, 92);
        color: #111111;
      }
  

      @media only screen and (max-width: 600px) {
    
    

            .iniciobtn3 {
              height: 50px;
              width: 150px;
                padding: 1em 3em;
                font-size: 10px;
                letter-spacing: 1px;
      
               
              }
              .regbtn {
                height: 50px;
                width: 100px;
                  padding: 1em 1em;
                  font-size: 10px;
                  letter-spacing: 1px;
        
                 
                }
      }
      .wrapperc {
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:#fff;
    }
    
   .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: #7ac142;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 58;
  stroke-dashoffset: 58;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}


.crossmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: red;
  fill: red;
  animation: stroke1 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.crossmark {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px red;
  animation: fill1 .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.crossmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: strokee 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes strokee {
  100% {
    stroke-dashoffset: 0;
   
  }
}
@keyframes scale1 {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill1 {
  100% {
    box-shadow: inset 0px 0px 0px 30px red;
    
  }
}
  
    /* ver masi ftoos  */
  
  .textlinkverfotos{
  
    margin-top: 10px;
    color: grey;
    font-size: 15px;
    margin-left: 30px;
    
  }
  
  
  .textlinkverfotos:hover{
    color:#343434
  }
  
  
  .ola {
   height: 100%;
  width: 100%;
      justify-content: center;
      flex-direction: column;
        display: flex;
        align-items: center;
      /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
      
   
  }
  
  
  .modalidades {
    width: 200px;
    height: 100px;
    border-radius: 10px;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   display: flex;
   margin-left: 5%;
  }


    
  .containerP {
 
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
   display: flex;

   
  }
  
  
  .cardpiscina {
    width: 37px;
    height: 26px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #171E24;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    flex-direction: row;
    margin: 60px 10px 10px 10px;
   
  }
  
  .cardpiscina:hover {
    background-color: rgba(255, 255, 255, 0.6);
    width: 160px ;
  }
  
  .cardpiscina:hover .pageIconPool {
    color: #171E24;
  }
  
  .cardpiscina:hover .cardspool {
    color: #171E24;
    opacity: 1;
    display: flex;
  }
  
  .cardpiscina .pageIconPool {
    font-size: 16px;
    color: #171E24;
    
  }
  
  .cardpiscina .cardspool {
    font-size: 12px;
    color: transparent;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .carddesinscrever {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(240, 70, 67, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  border: 1px solid rgb(245, 245, 245, 0.6);
    cursor: pointer;
   
    flex-direction: row-reverse;
    transition: width 0.3s ease;
   
  }
  
  .carddesinscrever:hover {
    width: 153px;
  }
  
  
  .carddesinscrever:hover .cardsdesinscrever {
    opacity: 1;
    display: flex;
  }
  
  
  .carddesinscrever .cardsdesisncrever {
    font-size: 14px;
    color: transparent;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .conteudobold{
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  
  .conteudo{
    font-size: 15px;
    text-decoration: none;
    color:    white;

  }

  .conteudoboldblue{
    font-size: 15px;
    font-weight: bold;
    color: #27345b;
    text-decoration: none;
  }
  
  
  .conteudoblue{
    font-size: 15px;
    text-decoration: none;
    color:    #27345b;

  }
  .conteudo4{
    font-size: 15px;
    text-decoration: none;
    color:    #27345b;


  }

  .conteudo2{
    font-size: 14px !important;
    text-decoration: none;
    color: #0A56FA;
    justify-content: center;
    display: flex;
    align-items: center;
   

  }
  

  .conteudo3{
    font-size: 15px;
    text-decoration: none;
    color:    white;

  }
  
  .colorir{
    background-color: #27345b;
    border-radius: 50%;
  

  }
  
  .center {
    justify-content:center;
        display: flex;
        align-items: center;
    position: relative;
    width: 50%;
    height: 70%;
    flex-direction: column;
    border-radius: 30px;
    background: white;
    box-shadow: 5px 5px 5px #bebebe,
    -5px -5px -5px #bebebe;
  
  
  }

  .center3 {
    justify-content:center;
        display: flex;
        align-items: center;
    position: relative;
    width: 50%;
    height: 70%;
    flex-direction: column;
    border-radius: 30px;
    background: white;
    box-shadow: 5px 5px 5px #bebebe,
    -5px -5px -5px #bebebe;
  
  
  }

  .center2 {
    justify-content: center;
        display: flex;
        align-items: center;
    position: fixed;
    width: 25%;
    height: 35%;
    flex-direction: column;
    border-radius: 30px;
    background: white;
    box-shadow: 5px 5px 5px #bebebe,
    -5px -5px -5px #bebebe;
  
  
  }


  .row1 {
    justify-content:space-between;
    display: inline-flex;
    align-items: flex-start;

flex-direction: row;
width: 100%;


  }

  @media only screen and (max-width: 1200px) {
    .row1 {
      justify-content:space-between;
      display: inline-flex;
      align-items: flex-start;
  position: relative;
  flex-direction: row;
  height:25%;
  width: 100%;
 
  
    }

  }

  @media only screen and (max-width: 500px) {
    .row1 {
      justify-content:space-between;
      display: inline-flex;
  
  position: relative;
  flex-direction: row;
  height:25%;
 
 
  
    }

  }
  .row2 {
    justify-content:space-between;
    display: flex;
    align-items: flex-start;
position: absolute;
flex-direction: row;
height:60%;
width: 100%;
margin-bottom: 5px;
  }
  
  .bodyPage {
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: block;
    align-items: center;
    justify-content: center;
    background: #fff;
   
  }
  

  .bodyPage2 {
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
  }

  .bodyPage3 {
  
    width: 100vw;
    background: #f7f7f7;
    justify-content: center;
    align-items: center;
    display: flex ;
  }
  
  .bodyPage4 {
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f7f7;
  }
  .cardp {
    width: 70%;
    height: 20%;
    border-radius: 4px;
    display: flex;
  
  
  
  }
  
  .cardp p {
    width: 100%;
    height: 100%;
    flex: 5;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #27345b;
    border-color: transparent;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .cardp p:hover {
    flex: 8;
    background: #e0e0e0;
    box-shadow: 15px 15px 30px #bebebe,
               -15px -15px 30px #ffffff;
  }

  
  .cardp p span {
    max-height: 100%;
    min-width: 100%;
    padding: .5em;
    text-align: center;
    transition: all .5s;
   
    letter-spacing: .1em;
  }


  .cardp .phorario {
    width: 100%;
    height: 100%;
    flex: 5;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #f7f7f7;
    box-shadow: 5px 5px 10px #bebebe,
               -5px -5px 10px #ffffff;
  }
  
  .cardp .phorario:hover {
    flex: 8;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    transition: all .5s;
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #e0e0e0;
    box-shadow: 15px 15px 30px #bebebe,
               -15px -15px 30px #ffffff;
               flex-direction: column;
  }
  .cardp .phorario:hover .spanhorario {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }


  
  
  .cardp .phorario .spanhorario {
    max-height: 100%;
    min-width: 100%;
    padding: .5em;
    text-align: center;
    transition: all .5s;
   display: none;
    letter-spacing: .1em;
  }

  
  /*Basic structure of slider*/
  .sliderp{
 max-height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  
  }
  
  
  
  /*Area of images*/
  .wrapperp{
    max-height: 100%;
    display: flex;
    animation: slidep 10s infinite;
  
  }
  
  .fundop{
    
   
  max-width: 100%;
  max-height: 100%;
    gap: 5px;
    padding-left: .3em;
    padding-right: .3em;
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  
  }
  
  
  /*Animation activated by keyframes*/
  @keyframes slidep{
    0%{
        transform: translateX(0);
    }
    25%{
        transform: translateX(0);
    }
    30%{
        transform: translateX(-100%);
    }
    50%{
        transform: translateX(-100%);
    }
    55%{
        transform: translateX(-200%);
    }
    75%{
        transform: translateX(-200%);
    }
    80%{
        transform: translateX(-300%);
    }
    100%{
        transform: translateX(-300%);
    }
  }






/* Tabela das Listas  */
.table{
  table-layout: fixed;
  word-wrap: break-word;
}

.search{
  
  background-color: #fff;
  border-radius: 10px;
 height: 40px;
    width: 0px;
    transition: width 0.3s ease;
    position: absolute;
    top: 1px;
    left: -20px;
    
}


.MuiDataGrid-virtualScrollerContent{
  background-color: transparent;
}

.tabela{

  margin-inline: 40px;
  background-color: transparent !important;
}
@media (max-width: 800px) {
  .tabela {
    font-size: 12px !important;
    margin-inline: 0px;
  }

  .cardpiscina:hover {
    background-color: rgba(255, 255, 255, 0.6);
    width: 80px !important ;
  }
}


.tabela2{
  margin-top: 10px;
  margin-inline: -25px;
  background-color: transparent !important;
}
 .MuiDataGrid-row {
  height: 20px !important;
}
.MuiDataGrid-main {
  margin-inline: 60px;
  
}
.MuiDataGrid-footerContainer {
  margin-inline: 60px;
}

.MuiDataGrid-root{
  border-color: transparent !important;
  background-color: transparent !important;
  max-height: 500px !important ;

}
.MuiDataGrid-virtualScroller{
  background-color: transparent !important;
 
}
.divTabela{
  margin-top: 40px;
  width: 85%;
height: 85%;
flex-shrink: 0;
border-radius: 10px !important;
background-color: #FFFFFF99;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
overflow: hidden;

}
.divTabelaM{
  margin-top: 40px;
  width: 85%;
height: 75%;
margin-bottom: 10px;
flex-shrink: 0;
border-radius: 10px !important;
background-color: #FFFFFF99;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
overflow: hidden;

}
.divTabelaMarcar{
 
 height: 100vh;
  width: 100%;
  scroll-behavior: smooth;

  align-items: center;
  justify-content: center;
  display: flex;

  
}


@media only screen and (max-height: 1200px) {
  .divTabelaMarcar{
 
    width: 100%;
    scroll-behavior: smooth;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    display: flex;
  
  }
}
@media only screen and (max-height: 900px) {
  .divTabelaMarcar{
 
  
    width: 100%;
    scroll-behavior: smooth;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    display: flex;
  
  }
  
}
@media only screen and (max-height: 800px) {
  .containerrow3 {
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  
   
  }

}

@media only screen and (max-height: 700px) {
  .divTabelaMarcar{
 
    height: 100vh;
    width: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.divTabelaMarcar2{
 height: 100vh;
 width: 100%;
 max-width: 100%;
    scroll-behavior: smooth;
    align-items: center !important;
    justify-content: flex-start!important;
   flex-direction: column;
   display:flex;
    overflow-y: auto;
    padding-top: 2%;
    margin-bottom: 2%;
   

  }



.divMarcar{
 align-items: center;
 display: flex;
  height: 60vh;
  width: 100%;

}

.divResumo{
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 400px; 
  overflow: auto;
}

.divPagamento{
  align-items: center;
  flex-direction: column;
  margin:5;
  width:100%;
 
 }

.divMarcarMB{
  align-items: center;
  display: flex;
   height: 60vh;
   width: 100%;
   flex-direction: column;
 
 }

.divMarcar2{
  align-items: center;
  justify-content: center;
  display: flex;
   height: 60vh;
   width: 100%;
 
 }
.divTabelaInscritas{
  margin-top: 5px;
  height: 30vh;
  width: 600;
}


.divTabela2{
  height: 80vh;
  width: 90%;
  align-items: center;
  background-color: #f7f7f7;
}

.titleList{
text-align: center;
margin-top: 55px;
font-size: 20px;
font-weight: 500;


}
.titleList2{
  text-align: center;
  margin-top: 35px;
  font-size: 20px;
  font-weight: 500;
  
  
  }
.titleMarcar{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  
  
  }

.buttonList{
  text-align: end;
  background-color: #0b1d42;
}

/* Página de Manutenção */
.wrapperManutencao {
  margin: 15vh auto;
  text-align: center;
  max-width: 90vw;
}

.svgManutencao {
  max-width: 80%;
  padding-top: 4%;
}

h1 {
  font-weight: 600;
  color: #263848;
  font-size: 32px;
}
p {
  font-weight: 600;
  color: #8b9fb2;
  font-size: 20px;
}

@keyframes floating {
  0% {
    animation-timing-function: ease;
    transform: translate3d(0, 0, 0);
  }
  50% {
    animation-timing-function: ease;
    transform: translate3d(0, 10%, 0);
  }
  100% {
    animation-timing-function: ease;
    transform: translate3d(0, 0, 0);
  }
}

#floater-01,
#floater-02,
#floater-03,
#floater-04 {
  animation-name: floating;
  transform-origin: center bottom;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

#floater-01,
#yellow-dot-01,
#blue-dot-01 {
  animation-duration: 9s;
}

#floater-02,
#yellow-dot-02,
#blue-dot-02 {
  animation-duration: 9.5s;
}

#floater-03,
#yellow-dot-03,
#blue-dot-03 {
  animation-duration: 10s;
}

#floater-04,
#yellow-dot-04 {
  animation-duration: 10.5s;
}

@keyframes floating2 {
  0% {
    animation-timing-function: ease;
    opacity: 1;
  }
  70% {
    animation-timing-function: ease;
    opacity: 0;
  }
  100% {
    animation-timing-function: ease;
    opacity: 1;
  }
}

#yellow-dot-01,
#yellow-dot-02,
#yellow-dot-03,
#yellow-dot-04,
#blue-dot-01,
#blue-dot-02,
#blue-dot-03,
#grey-dot-01,
#grey-dot-02,
#grey-dot-03,
#grey-dot-04,
#grey-dot-05,
#grey-dot-06 {
  animation-name: floating2;
  transform-origin: center bottom;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

#yellow-dot-01,
#blue-dot-01,
#grey-dot-02,
#grey-dot-06 {
  animation-duration: 8s;
}

#yellow-dot-02,
#blue-dot-02,
#grey-dot-03 {
  animation-duration: 12s;
}

#yellow-dot-03,
#blue-dot-03,
#grey-dot-4 {
  animation-duration: 10s;
}

#yellow-dot-04,
#grey-dot-01,
#grey-dot-05 {
  animation-duration: 7s;
}

@keyframes shine {
  0% {
    animation-timing-function: ease;
    opacity: 0.32;
  }
  70% {
    animation-timing-function: ease;
    opacity: 0.6;
  }
  100% {
    animation-timing-function: ease;
    opacity: 0.32;
  }
}

#shine {
  animation-duration: 10s;
  animation-name: shine;
  transform-origin: center bottom;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.buttonBack{
  font-size: 20px;
  color: var(--header-color);
  border-color: white;
}

.buttonBackP{
  color: white;
}

.buttonBackP:hover{
border: none;
box-shadow: none;
transform: none;
}

.align-left{ 
  float: left;
  width:15%;
  text-align: center; 
}

.align-left2{ 
  float: left;
  padding-top:0.5%;
  padding-bottom:0.5%;
}


.align-right{ 
  float: right;
  width:70%; 
  text-align: start;
}

.align-rightSA{ 
  float: right;
  width:100%; 
  text-align: start;
}




.divForm{
 
  /* position: absolute; */
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow:scroll;
  background-color: transparent;
}

.arrowBack {
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
}
.arrowBack:hover {
  background-color: wheat;
  transform: none;
  box-shadow: none;
}
.arrowBack:hover:active {
  transform: none;
}

.formPage{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.formPageSA{
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.formPage5{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
}


.formPage2{
 padding-right: 40px;
 padding-left: 40px;
  width: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
  text-align: center;
  overflow-y: auto;
  background-color: transparent;
}
.formPage2_1 {
  padding-right: 40px;
  padding-left: 40px;
  width: 90%;
  height: 93%;
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow-y: auto;
  background-color: blue;
}


.formPage3{
  margin-top: 80px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.formPage4{
  width: 100%;
  text-align: center;
}



.containerForm{
  justify-content: center;
  /* display: flex; */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 100px;
  width: 80%;
}

.containerForm2{
  justify-content: center;
  /* display: flex; */
  align-items: center;
  width: 80%;
}

.containerForm3{
  justify-content: center;
  /* display: flex; */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 100px;
  width: 80%;
}

.containerFormSA{
  justify-content: center;
  /* display: flex; */
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 100%;
}

.formTextField{
 
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 15px;
  background-color: #FFFFFF60;
  overflow:hidden

}

.ScrollbarsCustom-Track {
  background-color: #D2D1CF !important;
  width: 8px !important;
  opacity: 0.6 !important;
  ;
}

.ScrollbarsCustom-Thumb{
  background-color: #626669 !important;
 margin:1px !important;
 width: 6px !important;
}
.formTextFieldPass{
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
  background-color: white;
  flex-direction: column;
  display:flex; 
}

.formTextField2{
  padding-right: 3% !important;
  padding-left: 3% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}
.formTextField22{
  padding-right: 3% !important;
  padding-left: 1.5% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  
}

.formTextField3{
  padding-right: 3% !important;
  padding-left: 3% !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
}

.formTextField4{
  margin-right: 3% !important;
  padding-top: 1% !important;
  padding-bottom: 0.5% !important;
}

.formTextField5{
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 15px;
  background-color: transparent;
}

.h5List{
  text-align: left;
  padding-right: 3% !important;
  padding-left: 3% !important;
  padding-top: 1% !important;
  padding-bottom: 0.5% !important;
}

.buttonAdicionar {
  width: 95%;
  text-align: right;
  padding-bottom: 20px;
}

.react-tel-input .country-list .search{
  border-radius: 0px;
}

.input-phone-number input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}



/* Headers das tabelas usadas para as listas */
.MuiDataGrid-columnHeaderTitle{
  font-weight: 800 !important;
}

/* Formulários */
.PhoneInputCountry{
  display: flex !important;
  justify-content: center !important;
  align-items: end !important;
  padding-bottom: 3px !important;
}

.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] > select:disabled{
  opacity: 0;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
}

.verificarDados{
  font-weight: 100 !important;
  font-size: 16px;
  color: #333333;
  width: 100%;
  text-align: justify;
}

.verificarDadosDiv{
  /* text-align: center; */
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.h5List2{
  /* text-align: center; */
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  font-weight: 900;
}

.footerIcons:hover{
  color: lightgrey;
  
}


.footerIcons2:hover{
  color: lightgrey;
  
}
@media only screen and (max-width: 800px) {


  .footerIcons{
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .footerIcons2{
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .divPage{
    padding-top: 23vh;
  }
  
 
 
  
  
  .formTextField2{
    padding-right: 6% !important;
    padding-left: 6% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
    font-size: 12px !important;
  }
  .formTextField22{
    padding-right: 6% !important;
    padding-left: 6% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;

  }
  .formTextField3{
    padding-right: 6% !important;
    padding-left: 6% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
  }

  .divErrorPage{
    display: grid !important;
    justify-content: center;
    align-content: center;
    position: relative;
  }
  
  .divErrorPage .divErrorRight h1{
    font-size: 7em !important;
  }


  .divErrorPage .divErrorSuspensa h1{
    font-size: 3em !important;
  }


  .imageErrorPage{
    width: 350px !important;
    height: 200px !important;
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .divErrorPage{
    width:80% !important;
    text-align: center;
  }
  
}

@media (max-width: 800px) {
  .formPop .formTextField2 input,
  .formPop .formTextField2 label

 {
    font-size: 12px !important;
  }


  .formPage2 .formTextField2 input,
  .formPage2 .formTextField2 label

 {
    font-size: 12px !important;
  }

  .formPage2 .formTextField3 input,
  .formPage2 .formTextField3 label

 {
    font-size: 12px !important;
  }
  .formPop .formTextField3 input,
  .formPop .formTextField3 label

 {
    font-size: 12px !important;
  }
}













@media (max-width: 800px) {
  /* Estilos para todos os Selects */
  .MuiSelect-select{
    font-size: 12px !important;
  }

  /* Estilos para todos os MenuItems */
  .MuiMenuItem-root{
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 300px) {
  .divErrorPage .divErrorRight h1{
    font-size: 5em !important;
  }

  .divErrorPage .divErrorRight h4{
    font-size: 1em !important;
  }

  .divErrorPage .divErrorRight .buttonsErroPage button{
    font-size: 1em !important;
  }

  .imageErrorPage{
    width: 300px !important;
    height: 150px !important;
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .divErrorPage{
    width: 80% !important;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .divErrorPage .divErrorSuspensa h1{
    font-size: 3em !important;
  }

  .divErrorPage .divErrorSuspensa h4{
    font-size: 0.9em !important;
  }

  .divErrorPage .divErrorSuspensa .buttonsErroPage button{
    font-size: 0.9em !important;
  }

  .imageErrorPage{
    width: 300px !important;
    height: 150px !important;
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media only screen and (max-width: 1000px) {
  .slider {
   display: none;
  }
  .left{
    display: none;
  }
  .wrapper{
    display: none;

  }
  .fundo{
    display: none;
  }
  




 
  
}

.pageIcon{
  color: white;
  font-size: 22px;
   margin-bottom: 10px ;
   margin-right: 10px;
    display: flex;
    text-align: center;
 

}

.pageIconPool{
  color: #27345b;
  font-size: 16px;
    display: flex;
    justify-content: center;
   
 

}





.pageIcon2{
    color: white;
    font-size: 18px;
    margin-top: 15%;
    
   

}


.pageIcon4{
  color: white;
  font-size: 30px;
 
  
 

}

.pageIcon3{
  color: white
  
  
 

}

.gridAlign{
  justify-content: center;
  display: flex;
  align-items: center;
position: relative;
    

}

.modalstyle {
  position: relative;
  justify-content: center;
  display: flex;
  transform: 'translate(-50%, -50%)';
  background-color: wheat;
  border: '2px solid #000';
  box-shadow: 24;
top:30%;

}

.cardpiscina2{
  
  width: 300px;
  height: 50px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px #27345b;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
  background-color:  #27345b;
  flex-direction: row;
margin: 0px 1% 10px 4% ;
border-style: solid;

}

@media only screen and (max-width: 1200px) {

.center{
  justify-content: flex-start;
        display: flex;
        align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0px;
    background: white;
   

}

.center3{
  justify-content: flex-start;
        display: flex;
        align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0px;
    background: white;
   
   

}
.containerrow {
 
  display: flex;
  flex-direction:row;
  width: 80%;
  align-items: center;
  justify-content: center;
 
}


.containerrow1 {
  width: 100%;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
 
 
}


.cardpiscina2{
  
  width: 300px;
  height: 50px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px #27345b;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
  background-color:  #27345b;
  flex-direction: row;
margin: 0px 1% 10px 4% ;
border-style: solid;

}



}


@media only screen and (max-width: 1100px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;
  
     
  
  }
}

@media only screen and (max-width: 700px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;
  
     
  
  }
}


@media only screen and (max-width: 500px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;
   
     
  
  }
}


@media only screen and (max-width: 400px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;
  
     
  
  }
}

@media only screen and (max-width: 350px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;
   
     
  
  }
}


@media only screen and (max-width: 300px) {

 
  .center3{
    justify-content: flex-start;
          display: flex;
          align-items: center;
      position: relative;
      width: 100%;
      height:100%;
      flex-direction: column;
      border-radius: 0px;
      background: white;

     
  
  }
}

@media only screen and (max-width: 700px) {
  .conteudo{
    font-size: 8px;
  }

.conteudo2{
font-size: 8px;
}
.conteudo3{
  font-size: 9px;
}
.conteudo4{
  font-size: 10px;
  align-items: flex-start;
}

.conteudobold{
  font-size: 8px;
}

.cards{
  font-size: 11px;
  margin: 10px;
}

.pageIcon{
  font-size: 12px;
  margin:10px;
  display: none;
 
}





.userbtn{
  width: 150px;
  height:50px;
  font-size: 10px;
}

.desativarbtn{
  width: 200px;
  height:70px;
}

.excluirbtn{
  width: 200px;
  height:70px;
}

.pedidosbtn{
  width: 150px;
  height:50px;
  font-size: 10px;
}


}



@media only screen and (max-width: 800px) {
  .conteudo{
    font-size: 8px;
  }

.conteudo2{
font-size: 7px;
}
.conteudo3{
  font-size: 8px;
}
.conteudo4{
  font-size: 9px;
  align-items: flex-start;
}

.conteudobold{
  font-size: 8px;
}

.cards{
  font-size: 10px;
  margin: 10px;
}

.pageIcon{
  font-size: 10px;
  margin:10px;
  display: none;
}


.titulo{
  font-size: 14px;
  margin-bottom: 20px;
}

.subtitulo{
  font-size: 12px;
  
  color: grey;
}

}


.topright{
position: absolute;
top:20px;
right: 20px;
background-color: transparent;
}

.toprightDefinicoes{
  position: absolute;
  bottom:20px;
  right: 20px;
  background-color: transparent;
  }

.topleft{
  position: absolute;
  top:20px;
  left: 20px;
  background-color: transparent;
  }

  .topleftpoolp{
    position: absolute;
    top:8%;
    left: 2%;
    background-color: transparent;
    }

  .topleftcliente{
    position: absolute;
    top:10%;
    left: 85px;
    background-color: transparent;
    }

    .topleftmarcar{
      position: relative;
      top:20px;
      left: -10px;
      background-color: transparent;
      
    
      }

    .toprightcliente{
      position: absolute;
      top:10%;
      right: 15px;
      background-color: transparent;
      }

  .topright2{
    position: absolute;
    top:20px;
    right: 20px;
    background-color: transparent;
    }
    
    .topleft2{
      position: absolute;
      top:20px;
      left: 20px;
      background-color: transparent;
      }

  .topleftrel{
    position: relative;
    top:23.5%;
    margin-left: 30%;
    background-color: transparent;
    }

    @media (max-width: 800px) {
  .topleftrel {
    position: absolute;
    top:20px;
    margin-left: 0;
  }
}

@media (max-height: 800px) {
  .topleftrel {
    position: absolute;
    top:20%;
    margin-left: 30%;
    z-index: 999;
  }
}

    .topleftrel2{
      position: absolute;
      top:20px;
      margin-right: 24%;
      background-color: transparent;
      }
  

.bottomright{
  position: absolute;
  bottom:22px;
  right: 2px;
  display: none;
  background-color: transparent;
  }

  .bottomleft{
    position: absolute;
    bottom:22px;
    left: 2px;
    display: none;
    
    
    }

    .bottomright2{
      position: absolute;
      bottom:22px;
      right: 10px;
      display: block;
      width: 8%;
      }

      .bottomright4{
        position: absolute;
        bottom:20px;
     
        right: 20px;
        display: block;
        width: -120px;
        }

      .bottomright3{
        position: absolute;
        display: block;
        right: 10px;
        width: 8%;
        }
  

  @media only screen and (max-width: 1200px) {
.bottomright{
  display:block
}
.bottomleft{
 
  display: block;
  }

  .topright2{
 display:none;
    }
    
    .topleft2{
     display: none;
      }
}

/* Página Erro 404 */

.containerErrorPage{
  position: fixed;
  left: 45%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.divErrorPage{
  display: flex; 
  justify-content: center;
  align-content: center;
  position: relative;
}

.divErrorRight{
  display: grid;
  padding-left: 20px;
}

.divErrorSuspensa{
  display: grid;
  padding-left: 20px;
}


.divErrorPage .divErrorRight h1{
  color: var(--header-color);
  font-size: 11em;
  text-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
}

.divErrorPage .divErrorSuspensa h1{
  color: var(--header-color);
  font-size: 4em;
  text-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
}

.divErrorPage .ridivErrorRightght h4{
  margin-top: -2rem;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.buttonsErroPage > button{
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 25px !important;
  border: none;
  box-shadow: 0 5px 12px rgba(0, 0, 0 ,0.2);
  transition: 0.5s;
  background: #50c2fe;
  color: #e9e9e9;
  margin: 1.7rem 0;
  cursor: pointer;
}

.buttonsErroPage > button:nth-child(2){
  background-image: linear-gradient(to bottom right, #f75959, #e71313);
  margin: 1rem 0.5rem;
}

.buttonsErroPage > button:hover{
  border-radius: 25px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-21%);
}

.imageErrorPage{
  width: 450px;
  height: 300px;
  padding-right: 20px;
}

/* Footer Externo  */
.footerExterno {
  background-color: #09134f;
  height: 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.footerExterno, .footerText:hover, .footerText{
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  }

.listapiscinas{
  margin:20px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

/* Modalidades switch */
.MuiFormControlLabel-root{
  text-align: start;
  margin-left: 0px !important;  
}

.MuiFormControlLabel-root .MuiFormControlLabel-label{
  width: 100%;
}

.cardsDivPiscina{
  display: flex;
  justify-content: center;
  width: 100%;
}
.cardsDivPiscina2{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cardsDivClienteF{
  display: flex;
  padding-right: 5% !important;
  padding-left: 5% !important;
  justify-content: center;
  width: 100%;
}
.cardsDivClienteF2{
  margin-top: 20px;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  justify-content: center;
  width: 100%;
}


.cardsDivPiscina3{
  margin-top: 20px;
  
  display: flex;

  width: 100%;
  justify-content: center !important;
}

.cardsDivPiscina22{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cardsDivPiscina3{
  margin-top: 20px;
  text-align: center;
}

.iconCards{
  font-size: 25px !important;
  padding-right: 7%;
}

.iconCardsF{
  font-size: 15px !important;
  padding-right: 7%;
}

.iconCardsFamilia{
  font-size: 70px !important;
  color: whitesmoke;
}
@media only screen and (max-width: 800px) {
  .iconCardsFamilia{
    font-size: 30px !important;
    color: whitesmoke;
  }
}


.cardOpcoesPiscina1{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-left: 10% !important;
  margin-top: 2% !important;
  margin-right: 10% !important;
}
.cardOpcoesClientesF1{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;

  margin-top: 2% !important;
  
}
.cardReenviarEmail{
  width: 30%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 70px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;

    margin-top: 2% !important;
}

.cardOpcoesPiscina1_1{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-left: 10% !important;
  margin-top: 2% !important;
  margin-right: 10% !important;
}

.cardOpcoesClientesF1_1{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  
  margin-top: 2% !important;
 
}
.cardOpcoesPiscina2{
  width: 50%;
  margin-right: 2% !important;
  margin-left: 10% !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
}
.cardOpcoesPiscina2_2{
  width: 50%;
  margin-right: 2% !important;
  margin-left: 10% !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
}
.cardOpcoesPiscinaAtivar{
  width: 30%;
  
  margin-left: 10% !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}
.cardOpcoesPiscina3{
  width: 50%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 10% !important;
}

.cardOpcoesPiscinaEliminar3{
  width: 50%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 2% !important;
}

.cardOpcoesPiscinaEliminar{
  width: 30%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 2% !important;
  margin-left: 2% !important;
}

.cardOpcoesFilhoEliminar{
  width: 80%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
 
}
.cardOpcoesPiscina4{
  width: 25%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-left: 10% !important;
}
.cardOpcoesPiscina5{
  width: 25%;
  margin-left: 2% !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}
.cardOpcoesPiscina6{
  width: 25%;
  margin-left: 2% !important;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 2% !important;
}

.cardOpcoesPiscina7{
  width: 25%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 10% !important;
}

.cardOpcoesPiscinaContaF{
  width: 30%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 2% !important;
}
.cardOpcoesPiscinaReenviar{
  width: 30%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 10% !important
 
}

.cardOpcoesPiscinaReenviar2{
  width: 50%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-right: 10% !important
 
}
@media only screen and (max-width: 1300px) {
  .cardOpcoesPiscina1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardsDivClienteF2{
    margin-top: 20px;
    padding-left: 3%;
    padding-right: 2%;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .cardOpcoesClientesF1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .cardReenviarEmail{
    width: 40%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 70px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .cardOpcoesClientesF1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2{
    width: 80%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2_2{
    width: 80%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-left: 0px !important;
  }

  .cardOpcoesPiscinaAtivar{
    width: 50%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
  }
  .cardOpcoesPiscina3{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 10% !important;
    margin-right: 0px !important;
  }


  .cardOpcoesPiscinaEliminar3{
    width: 80%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 2% !important;
  }

  .cardOpcoesPiscinaEliminar{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 2% !important;
    margin-left: 0% !important;
  }



  .cardOpcoesFilhoEliminar{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 900 !important;
    margin-right: 10% !important;
    margin-right: 0px !important;
  
  }
  .cardOpcoesPiscinaContaF{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 2% !important;

  }

  .cardOpcoesPiscinaReenviar{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0% !important;

  }
  .cardOpcoesPiscinaReenviar2{
    width: 80%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;

  }
  .cardOpcoesPiscina4{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
  }
  .cardOpcoesPiscina5{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscina6{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
  }
  
  .cardOpcoesPiscina7{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-right: 0% !important;
  }
}

@media only screen and (max-width: 850px) {

  .marcar{
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;

  
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
    
  }
  .cardOpcoesPiscina1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .cardOpcoesClientesF1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardReenviarEmail{
    width: 40%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesClientesF1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2{
    width: 50%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2_2{
    width: 50%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscinaAtivar{
    width: 30%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscinaEliminar{
    width: 30%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }

  .cardOpcoesPiscinaEliminar3{
    width: 50%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }

  .cardOpcoesPiscinaContaF{
    width: 30%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscinaReenviar{
    width: 30%;
    margin-right: 0% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }

  .cardOpcoesPiscinaReenviar2{
    width: 50%;
    margin-right: 0% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscina3{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 10% !important;
    margin-right: 0px !important;
  }
  .cardOpcoesPiscina4{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
  }
  .cardOpcoesPiscina5{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscina6{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
  }
  
  .cardOpcoesPiscina7{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 900 !important;
    margin-right: 0% !important;
  }
}

@media only screen and (max-width: 700px) {
  .iconCards{
    font-size: 20px !important;
    padding-right: 7%;
  }
  .iconCardsF{
    font-size: 15px !important;
    padding-right: 7%;
  }

  .cardOpcoesPiscina1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesClientesF1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesClientesF1_1{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2{
    width: 50%;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-left: 0px !important;
  }
  .cardOpcoesPiscina2_2{
    width: 5%0;
    margin-right: 2% !important;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscina3{
    width: 50%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 10% !important;
    margin-right: 0px !important;
  }
  .cardOpcoesPiscina4{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
  }
  .cardOpcoesPiscina5{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
  }
  .cardOpcoesPiscina6{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
  }
  
  .cardOpcoesPiscina7{
    width: 45%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 900 !important;
    margin-right: 0% !important;
  }
}




@media only screen and (max-width: 600px) {
  .cardOpcoesPiscina1{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesClientesF1{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardReenviarEmail{
    width: 60%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 40px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .cardOpcoesFilhoEliminar{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina1_1{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesClientesF1_1{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina2{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina2_2{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina3{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina4{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina5{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .cardOpcoesPiscina6{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  
  .cardOpcoesPiscina7{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
 
}


@media only screen and (max-width: 300px) {

  .cardReenviarEmail{
    width: 90%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 40px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 900 !important;
    margin-bottom: 2% !important;
   
  }

}
.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track, .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: #1976d2 !important;
}

.headerModoEdicao{
  background-color: #f7f7f7;
  width: 100%;
  position: fixed;
  height: 40px;
  display: flex;
  z-index: 100;
  justify-content: space-between;
}

.cancelarEdicao{
  margin-left: 5% !important;
  margin-right: 5% !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.guardarEdicao{
  margin-right: 5% !important;
  margin-left: 5% !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 10px !important;
}

.caminhoPaginas{
  display: flex;
  justify-content: center;
  align-items: center;
}

.caminhoPaginas2{
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  align-items: flex-start;
}

.addButton{
  height: 42px !important;
  width: 42px !important;
  background-image: url('images/adicionar.svg') !important;
background-size: fill;
background-repeat: no-repeat;
background-position: center ;
border-radius: 10px;

 
}
.addButton:hover {
  transform: scale(1.05);
  background-size: 107% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}

.searchButton{
  height: 42px !important;
  width: 42px !important;
  background-image: url('images/search.svg') !important;
background-size: fill;
background-repeat: no-repeat;
background-position: center center;
border-radius: 10px;


 
}
.searchButton:hover {
  transform: scale(1.05);
  background-size: 107% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.caminhoPaginas3{
  display:flex;
  float:left;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}
.caminhoPaginas_a{
  color: #bfbfbf;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
 
}

.caminhoPaginas_a2{
  color: #454545;
  font-size: 16px;
  text-decoration: none;
  margin-left: 1px;
  margin-right: 1px;
}

#divCaminhos{
  text-align: end;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 850px) {
    #divCaminhos{
      text-align: end;
      margin-top: 20px;
      display: contents !important;
      max-width: 90% !important;
    }
    .caminhoPaginas{
      float: left !important;
      margin-bottom: 10px;
      width: 100% !important;
      padding-left: 50% !important;
      padding-right: 50% !important;
    }

    
  
    .caminhoPaginas_a, .caminhoPaginas_a2{
      font-size: 10px;
      
    }

 
  .confirmadadostext{
    font-size: 12px !important;
    color:#0b1d42 !important;
  }

  .ativarconta{
    font-size: 17px;

  }
}

.addButton1{
  margin-right:10 !important;
}

@media only screen and (max-width: 850px) {
  .addButton1{
    margin-right: 5 !important;

  }


  .calendar2{

    width: 80% !important;
    margin-top: 15% !important;
  
  }

  .calendar3{

    width: 80% !important;
    margin-top: 30% !important;
    align-items: center;
  
  }


}

.formPageSuspensao2 {
  margin: -12px auto 10px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.botaolistaadmin {
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid white;
  background: #C2D5FE;
  color: white;
  margin: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.botaolistaadmin2 {
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid white;
  background: #C2D5FE;
  color: white;
  margin: 6px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.botaolistaadmin:hover,.botaolistaadmin2:hover, .botaolistaadminselecionado {
  background-color: #0A56FA;
}
.formPageSuspensao{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 25px;
  }
  .formPageSuspensao3{
    margin-top: 10px;
    margin-bottom: 3px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 25px;
  }
.cardsDivDashboard{
  display: flex;
 
}

.cardsDivMarcar{
  display: flex;
  justify-content: center;
width:800px;
align-items: center;
  padding-top: 20px;
 

}

.cardsDivPagamentoAulas{
  display: flex;
  justify-content: center;
flex-direction: column;
align-items: center;
margin-top: 10%;
 

}


.cardsDivMarcarPagamento{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cardsDivMarcarPagamento2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.cardDashboardMarcar3{
  width: 180%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 200px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

.cardFuturo{
  width: 90%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  
}

.cardDashboardMarcar4{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 200px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
}
@media only screen and (max-width: 1200px) {
  .cardsDivMarcarPagamento{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin-left: 5%;
  
  }
  .cardsDivMarcarPagamento2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .cardDashboardMarcar3{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }

  .cardFuturo{
    width: 80%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 10px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}



.cardsDivMarcar2{
  display: flex;
  justify-content: center;
  width: 100%;

}

.cardsDivDashboard2{
  margin-top: 16px;
  display: flex;

}
.plusIcon{
  
  display: inline-block;
  position:absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
}



.plusshadowIcon {
  
  display: inline-block;
  position: absolute;
  opacity: 0.6;
  width: 24px;
  height: 24px;
 
}
.navbarClienteBottom2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  z-index: 99;
  bottom: 0;
  background: #171E24;
  overflow: hidden;
}
.navbarClienteBottom {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50px;
  z-index: 99;
  bottom: 0;
  background: #171E24;
  overflow: hidden;
  border-radius: 7px 7px 0px 0px;
}
.navbarCliente{
  position: absolute;
  display: flex;
  align-items: center; 
  left: 0%;
  width: 200px;
  top: 0%;
  bottom: 0%;
  background: #171E24;
 
  transition: width 0.5s ease;
  overflow: hidden;
  /* outline: 20px solid greenyellow */

  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.navbarCliente:hover {
  width: 200px; /* Mantém a largura em 200px no hover */
}

.navbarCliente:hover ~ .listPages {
  margin-left: 200px;
}

.navbarCliente:hover ~ .divTabela {
  width: 800px ;
}

.navbarCliente:not(:hover) {
  width: 50px; /* Define a largura para 50px quando não estiver em hover */
}

.additionalOptions{
  position: fixed;
  display: flex;
  z-index: 99999 !important;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 180px; 
  bottom: 45px  !important; 
  border-radius: 7px 7px 0px 0px;
  background: #171E24  !important;
  border-bottom:2px solid rgba(255, 255, 255, 0.20);
  overflow: hidden;
  transition: height 0.3s ease; 
}

.navbarCliente2{
  position: absolute;
  display: flex;
  align-items: center; 
  left: 0%;
  width: 270px;
  top: 0%;
  bottom: 0%;
  background: #171E24;
 
  transition: width 0.5s ease;
  overflow: hidden;
  /* outline: 20px solid greenyellow */

  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.navbarCliente2:hover {
  width: 270px; /* Mantém a largura em 200px no hover */
}

.navbarCliente2:hover ~ .listPages {
  margin-left: 270px;
}

.navbarCliente2:not(:hover) {
  width: 50px; /* Define a largura para 50px quando não estiver em hover */
}


.logonav{
  position: absolute;
  width:28px !important;
  height: 35px !important;
left: 11px !important;
top: 11px !important;
cursor: pointer;
background-image: url("images/logo.svg");
background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
z-index: 103;
}

.MenuCriancas{
  cursor: pointer;
background-image: url("images/MenuCriancas.svg");
background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
}
.MenuCriancas2{
  cursor: pointer;
background-image: url("images/MenuCriancas2.svg");
background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;
}


.olanav{
  position: absolute;
  font-family: 'Lexend Deca';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 30px;
display: none;
align-items: center;
width:50;
color: #FFFFFF;
left: 75px !important;
top: 15px !important;
z-index: 103;
}

.navbarCliente:hover .olanav{

display: flex;
  }


.navbarCliente:hover + .navbar{
  
      left:200px;
      transition: left 0.5s ease; 
      
      }

.navbarCliente2:hover .olanav{

        display: flex;
          }
        
.navbarCliente2:hover + .navbar{
          
left:270px;
transition: left 0.5s ease; 
              
}

.navIconsBottom{

  color:#a2a5a7;
  width:20px !important;
  height: 20px !important;
}

.navIconsBottomPedidos{

  color:#F04643;
  width:20px !important;
  height: 20px !important;
}

.navIcons{

  color:#a2a5a7;
  width:20px;
  transition: opacity 0.5s ease;
 

}

.navIconsPedidos{

  color:#F04643;
  width:20px;
  transition: opacity 0.5s ease;
 

}
.Mui-selected > .navIcons , .Mui-selected > .MuiBottomNavigationAction-label, .Mui-selected > .navOptionText{
  color: #00C4F5
;
}
.Mui-selected > .navIconsPedidos , .Mui-selected > .MuiBottomNavigationAction-label, .Mui-selected > .navOptionText{
  color: #F04643
;
}
.Mui-selected > .navIconsBottom , .Mui-selected > .MuiBottomNavigationAction-label, .Mui-selected > .navOptionText{
  color: #00C4F5
;
}
.Mui-selected > .navIconsBottomPedidos , .Mui-selected > .MuiBottomNavigationAction-label, .Mui-selected > .navOptionTextPedidos{
  color: #F04643
;
}
.navOption{
  display:flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
}

.bottomNavigationAction {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transition: none;
  margin-top: 25px !important;
  margin-bottom: 25px!important;
  flex-direction: row;
 

}
.bottomNavigationAction4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  margin-right: -5px !important;
  margin-left: -5px!important;
  flex-direction: row;
 

}
.bottomNavigationAction4Pedidos {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  margin-right: -5px !important;
  margin-left: -5px!important;
  flex-direction: row;
 

}
.bottomNavigationAction3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  margin-right: 5px !important;
  margin-left: 5px!important;
  flex-direction: row;
 

}
.bottomNavigationAction2 {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transition: none;
  margin-top: 10px !important;
  margin-bottom: 10px!important;
  flex-direction: row;
 

}

.bottomNavigationAction3Pedidos {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  margin-right: 5px !important;
  margin-left: 5px!important;
  flex-direction: row;
 

}
.bottomNavigationAction2Pedidos {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transition: none;
  margin-top: 10px !important;
  margin-bottom: 10px!important;
  flex-direction: row;
 

}


.bottomNavigationAction3 {
  display: inline-flex;
  align-items:center;
  justify-content: flex-start;
  transition: none;
  margin-top: 7px !important;
  margin-bottom: 7px!important;
  flex-direction: row;


}

.bottomNavigationAction3Pedidos {
  display: inline-flex;
  align-items:center;
  justify-content: flex-start;
  transition: none;
  margin-top: 7px !important;
  margin-bottom: 7px!important;
  flex-direction: row;


}


.bottomNavigationActionSelected {
  color: #00c4f5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
}
.bottomNavigationActionSelectedPedidos {
  color: #F04643;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
}

.bottomNavigationLabel {
  position: absolute;
 left: 90px;
  white-space: nowrap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.bottomNavigationLabel2 {
  position: absolute;
 left: 60px;
  white-space: nowrap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
 
  color: rgba(255, 255, 255, 0.6);
}
.navOptionText{


font-family: 'Lexend Deca';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
z-index: 99 !important;
color: rgba(255, 255, 255, 0.6);
opacity:0;
transition: opacity 0.5s ease;
margin-left: 10px; 

}

.bottomNavigationAction:not(.Mui-selected):hover .navOptionText {
  
  color: #ffffff; 
}

.bottomNavigationAction.Mui-selected .navOptionText {
  opacity: 1;
}

.navOptionTextPedidos{


  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  z-index: 99 !important;
  color: #F04643;
  opacity:0;
  transition: opacity 0.5s ease;
  margin-left: 10px; 
  
  }
  
  .bottomNavigationAction:not(.Mui-selected):hover .navOptionTextPedidos {
    
   color: #F04643;
  }
  
  .bottomNavigationAction.Mui-selected .navOptionTextPedidos {
    opacity: 1;
  }

.bottomNavigationAction:not(.Mui-selected):hover .navIcons  {
  
  color: #ffffff; 
}
.bottomNavigationAction:not(.Mui-selected):hover  .navIconsBottom {
  
  color: #ffffff; 
}
.bottomNavigationAction.Mui-selected .navIcons  {
  opacity: 1;
}
.bottomNavigationAction.Mui-selected  .navIconsBottom {
  opacity: 1;
}

/* Estilo para .bottomNavigationAction quando a tela é menor que 768px */
@media (max-width: 799px) {
  .bottomNavigationAction.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 5px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
}

/* Estilo para .bottomNavigationAction quando a tela é maior ou igual a 768px */
@media (min-width: 800px) {
  .bottomNavigationAction.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
}




.bottomNavigationAction:not(.Mui-selected):hover span {
  
  color: #ffffff; 
}

.bottomNavigationAction2:not(.Mui-selected):hover .navOptionText {
  
  color: #ffffff; 
}

.bottomNavigationAction2.Mui-selected .navOptionText {
  opacity: 1;
}

.bottomNavigationAction2:not(.Mui-selected):hover .navOptionTextPedidos {
  
  color: #F04643; 
}

.bottomNavigationAction2.Mui-selected .navOptionTextPedidos {
  opacity: 1;
}

.bottomNavigationAction2:not(.Mui-selected):hover .navIcons {
  
  color: #ffffff; 
}
.bottomNavigationAction2:not(.Mui-selected):hover .navIconsBottom{
  
  color: #ffffff; 
}
.bottomNavigationAction2.Mui-selected .navIcons  {
  opacity: 1;
}
.bottomNavigationAction2.Mui-selected  .navIconsBottom {
  opacity: 1;
}


@media (max-width: 799px) {
  .bottomNavigationAction2.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;

    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 5px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
  .bottomNavigationAction2Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;

    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 5px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
}

/* Estilo para .bottomNavigationAction quando a tela é maior ou igual a 768px */
@media (min-width: 800px) {
  .bottomNavigationAction2.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
  .bottomNavigationAction2Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
}


.bottomNavigationAction3:not(.Mui-selected):hover .navOptionText {
  
  color: #ffffff; 
}

.bottomNavigationAction3.Mui-selected .navOptionText {
  opacity: 1;
}

.bottomNavigationAction3:not(.Mui-selected):hover .navOptionTextPedidos {
  
  color: #F04643; 
}

.bottomNavigationAction3.Mui-selected .navOptionTextPedidos {
  opacity: 1;
}

.bottomNavigationAction3:not(.Mui-selected):hover .navIcons  {
  
  color: #ffffff; 
}
.bottomNavigationAction3:not(.Mui-selected):hover .navIconsBottom  {
  
  color: #ffffff; 
}
.bottomNavigationAction3.Mui-selected .navIcons {
  opacity: 1;
}
.bottomNavigationAction3.Mui-selected .navIconsBottom {
  opacity: 1;
}
.bottomNavigationAction4Pedidos:not(.Mui-selected):hover .navOptionText {
  
  color: #ffffff; 
}

.bottomNavigationAction4Pedidos.Mui-selected .navOptionText {
  opacity: 1;
}

.bottomNavigationAction4Pedidos:not(.Mui-selected):hover .navOptionTextPedidos {
  
  color: #F04643; 
}

.bottomNavigationAction4Pedidos.Mui-selected .navOptionTextPedidos {
  opacity: 1;
}

.bottomNavigationAction4Pedidos:not(.Mui-selected):hover .navIcons  {
  
  color: #ffffff; 
}
.bottomNavigationAction4Pedidos:not(.Mui-selected):hover .navIconsBottom  {
  
  color: #ffffff; 
}
.bottomNavigationAction4Pedidos.Mui-selected .navIcons {
  opacity: 1;
}
.bottomNavigationAction4Pedidos.Mui-selected .navIconsBottom {
  opacity: 1;
}

.bottomNavigationAction4:not(.Mui-selected):hover .navOptionText {
  
  color: #ffffff; 
}

.bottomNavigationAction4.Mui-selected .navOptionText {
  opacity: 1;
}

.bottomNavigationAction4:not(.Mui-selected):hover .navOptionTextPedidos {
  
  color: #F04643; 
}

.bottomNavigationAction4.Mui-selected .navOptionTextPedidos {
  opacity: 1;
}

.bottomNavigationAction4:not(.Mui-selected):hover .navIcons  {
  
  color: #ffffff; 
}
.bottomNavigationAction4:not(.Mui-selected):hover .navIconsBottom  {
  
  color: #ffffff; 
}
.bottomNavigationAction4.Mui-selected .navIcons {
  opacity: 1;
}
.bottomNavigationAction4.Mui-selected .navIconsBottom {
  opacity: 1;
}
@media (max-width: 799px) {
  .bottomNavigationAction3.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 5px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
  .bottomNavigationAction3Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;
    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 5px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
}

/* Estilo para .bottomNavigationAction quando a tela é maior ou igual a 768px */
@media (min-width: 800px) {
  .bottomNavigationAction3.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
  .bottomNavigationAction3Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
}
@media (max-width: 799px) {
  .bottomNavigationAction4.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 6px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
  .bottomNavigationAction4Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;
    width: 30px !important; /* Importante para sobrescrever o estilo anterior */
    height: 6px !important; /* Importante para sobrescrever o estilo anterior */
    bottom: 0 !important;   /* Importante para sobrescrever o estilo anterior */
    border-radius: 5px 5px 0px 0px !important;  
    left: unset !important; /* Remova a propriedade left para sobrescrever o estilo anterior */
  }
}

/* Estilo para .bottomNavigationAction quando a tela é maior ou igual a 768px */
@media (min-width: 800px) {
  .bottomNavigationAction4.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #00C4F5;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }

  .bottomNavigationAction4Pedidos.Mui-selected::after {
    content: '';
    position: absolute;
    background-color: #F04643;
    left: 15px;
    width: 5px;
    height: 30px;
    border-radius: 0px 5px 5px 0px;  
    bottom: unset !important; /* Remova a propriedade bottom para sobrescrever o estilo anterior */
  }
}

.navbarCliente:hover .navOptionText{
  opacity:1;

z-index: 102;
display: flex;
  }


  .navbarCliente2:hover .navOptionText{
    opacity:1;
  
  z-index: 102;
  display: flex;
    }

    .navbarCliente:hover .navOptionTextPedidos{
      opacity:1;
    
    z-index: 102;
    display: flex;
      }
    
    
      .navbarCliente2:hover .navOptionTextPedidos{
        opacity:1;
      
      z-index: 102;
      display: flex;
        }
.cardDashboardMarcarB:hover .plusshadowIcon {
  /* Definições da animação da sombra do ícone quando o botão é hover */
  display: inline-block;
}
.cardDashboardMarcarB:hover .plusshadowIcon{
  transform: rotate(30deg) translate(0, -6px);
  transition: transform 0.3s ease-in-out;
 
  

}

.cardDashboardMarcarB2:hover .plusshadowIcon {
  /* Definições da animação da sombra do ícone quando o botão é hover */
  display: inline-block;
}
.cardDashboardMarcarB2:hover .plusshadowIcon{
  transform: rotate(30deg) translate(0, -6px);
  transition: transform 0.3s ease-in-out;
 
  

}


.cardDashboard{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 100px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

.btnHoras{
  box-sizing: border-box;
  position: relative;
  width: 144px;
  height: 75px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0A56FA !important;
  overflow: hidden;
  border-radius: 10px;
  background-image: url('images/hoursCard.svg');
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;

}

.btnHorasD{
  box-sizing: border-box;
  position: relative;
  width: 140px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #0A56FA !important;
  background-color: #C2D5FE !important  ; */
  overflow: hidden;
  border-radius: 10px;
  /* background-image: url('images/hoursCard.svg'); */
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center center;

}

.cardPacks{
  
  position: relative;
  width: 250px;
  height: 100px;
  background: #0A56FA;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #0A56FA !important;
  overflow: hidden;
  border-radius: 10px !important;
  background-image: url('images/cardPacks.svg');
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;

}


.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 5px;
  justify-items: center;
}

.btnHoras2{
  width: 100%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 60px !important;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 900 !important;
  height: 40px !important;
  background-color: #a51b0b;
  color: white;

}


.gridHoras{

width: 40%;
  height: 400px;
  scroll-behavior: smooth;
  align-items: center;
  margin-top: 5% !important;

}

.gridHoras2{

  width: 80%;
    height: 300px;
    scroll-behavior: smooth;
    align-items: center;
    overflow-y:auto;
    margin-top: 5%;
  
  }

  .DayPicker {
    flex-direction: row;
    padding: 0;
  margin-top: 5% !important;
  }
  
  .DayPicker2 {
   flex-direction: column;
   justify-content: center;
  margin-top: 5% !important;
 

  }

@media only screen and (max-width: 1250px) {
  .gridHoras{

    width: 30%;
      height: 300px;
      scroll-behavior: smooth;
      margin-left: 30% ;
      align-items: center;
      overflow-y:auto;
      margin-top: 2% !important;
   
    }

    .DayPicker {
      flex-direction: row;
     margin-left: 20% !important;
      padding: 0;
    width: 20%;
    margin-top: 2% !important;
    }

}

@media only screen and (max-width: 400px) {
  .gridHoras2{


      height: 250px;
      scroll-behavior: smooth;
      align-items: center;
      overflow-y:auto;
      margin-top: 2% !important;
   
    }

    .DayPicker2 {
    margin-top: 2% !important;
    
    }
    
}



.cardDashboardCliente{
  width: 200%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 90px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.cardDashboardMarcarB {
  width: 226px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 120px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 19px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}

.cardDashboardMarcarB2 {
  width: 226px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 120px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 19px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}



/* Use @media para ajustar a largura em diferentes tamanhos de tela */
@media (max-width: 800px) {
  .btnEdicao {
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: 153px !important; /* Largura para telas menores */
  }
  .btnEdicao:hover {
    transform: scale(1.09);
    background-size: 104%;
    
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnEdicao:active {
    transform: scale(1.00);
    background-size: 102%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}

/* Se você quiser outra largura para telas maiores, adicione outra @media query */
@media (min-width: 801px) {
  .btnEdicao {
    margin-right: 8px !important;
    margin-left: 8px !important;
    width: 251px !important;
  }
  .btnEdicao:hover {
    transform: scale(1.09);
    background-size: 104%;
    
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnEdicao:active {
    transform: scale(1.00);
    background-size: 102%;
    
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}

.btnEdicao {
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 40px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;
}
.btnEdicao:hover {
  transform: scale(1.09);
  background-size: 104%;
  
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.btnEdicao:active {
  transform: scale(1.00);
  background-size: 102%;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  
}

.btnEdicao:focus {
  outline: none; 
}


/* Use @media para ajustar a largura em diferentes tamanhos de tela */
@media (max-width: 400px) {
  .btnEdicao {
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: 103px !important; /* Largura para telas menores */
  }
  .btnEdicao:hover {
    transform: scale(1.09);
    background-size: 104%;
    
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnEdicao:active {
    transform: scale(1.00);
    background-size: 102%;
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center ;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}

.btnAddHorario {
  width: 180px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 30px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}
.btnAddHorario:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.btnAddHorario:active {
  transform: scale(1.00);
  background-size: 102%;
  background-position: center;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  
}

.btnAddHorario:focus {
  outline: none; 
}


.btnGuardar {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;
  height: 40px !important;
  cursor: pointer;
  font-weight: 900 !important;
  border-radius: 10px !important;
}

.btnGuardar:focus {
  outline: none; 
}



/* Use @media para ajustar a largura em diferentes tamanhos de tela */
@media (max-width: 800px) {
  .btnGuardar {
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: 164px !important; /* Largura para telas menores */
    font-size: 13px !important;
  }
  .btnGuardar:hover {
    transform: scale(1.04);
    background-size: 104%;
    background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnGuardar:active {
    transform: scale(1.00);
    background-size: 102%;
    background-position: center;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}

/* Se você quiser outra largura para telas maiores, adicione outra @media query */
@media (min-width: 801px) {
  .btnGuardar {
    margin-right: 8px !important;
    margin-left: 8px !important;
    width: 218px !important; /* Largura para telas maiores */
  }
  .btnGuardar:hover {
    transform: scale(1.04);
    background-size: 104%;
    background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnGuardar:active {
    transform: scale(1.00);
    background-size: 102%;
    background-position: center;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}


/* Use @media para ajustar a largura em diferentes tamanhos de tela */
@media (max-width: 400px) {
  .btnGuardar {
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: 104px !important; /* Largura para telas menores */
    font-size: 13px !important;
  }
  .btnGuardar:hover {
    transform: scale(1.04);
    background-size: 104%;
    background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    
  }
  .btnGuardar:active {
    transform: scale(1.00);
    background-size: 102%;
    background-position: center;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
    
  }
}


.btnHorario {
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
 
  cursor: pointer;
  font-weight: 900 !important;
  background-size: 100%;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}
.btnHorario:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.btnHorario:active {
  transform: scale(1.00);
  background-size: 102%;
  background-position: center;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  
}

.btnHorario:focus {
  outline: none; 
}
.fc-list-empty{

  background-color: transparent !important;
}
.fc-list-day {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.btnSim {
  width: 198px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 30px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}
.btnSim:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.btnSim:active {
  transform: scale(1.04);
  background-size: 104%;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center ;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  outline: none !important; 
  
}

.btnSim:focus {
  outline: none !important; 
}

.cardDashboardMarcarB:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-position: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}

.cardDashboardMarcarB:active {
  transform: scale(1.00);
  background-size: 102%;
  background-position: center;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  
}

.cardDashboardMarcarB2:hover {
  transform: scale(1.04);
  background-size: 104%;
  background-position: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  
}

.cardDashboardMarcarB2:active {
  transform: scale(1.00);
  background-size: 102%;
  background-position: center;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2) !important;
  
}

.formPop {
  max-height: 500px;
  background-color: transparent;
  border-radius: 4px;

}


/* Use @media para ajustar o valor de margin-inline em diferentes tamanhos de tela */
@media (max-width: 799px) {
  .formPop {
    margin-inline: 2px; /* Para telas menores ou iguais a 800px */
  }
  .fc .fc-list-empty{

    width:50% !important
  }
}

/* Use @media para ajustar o valor de margin-inline para telas maiores ou iguais a 801px */
@media (min-width: 800px) {
  .formPop {
    margin-inline: 40px; /* Para telas maiores ou iguais a 801px */
  }

}

@media only screen and (max-width: 800px) {
  .btnEdicao{

font-size: 12px !important;
     
   
    }

    .btnAddHorario{

      font-size: 12px !important;
           
         
          }

          .btnGuardar{

            font-size: 12px !important;
                 
               
                }
                .btnSim{

                  font-size: 12px !important;
                       
                     
                      }
}


@media only screen and (max-width: 400px) {
  .btnEdicao{

font-size: 12px !important;
     
   
    }

    .btnAddHorario{

      font-size: 12px !important;
           
         
          }

          .btnGuardar{

            font-size: 12px !important;
                 
               
                }
                .btnSim{

                  font-size: 12px !important;
                       
                     
                      }
}

@media only screen and (max-width: 900px) {
  .cardDashboardCliente{
    width: 200%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 90px !important;
    font-size: 10px !important;

    cursor: pointer;
    color: #fff !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
 
}

@media only screen and (max-width: 800px) {

  .cardDashboard2{
    width: 105% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .cardDashboard3{
    width: 105% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}







@media only screen and (max-width: 600px) {
  .cardDashboardCliente{
    width: 200%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 90px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .cardDashboard2{
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .cardDashboard3{
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}

@media only screen and (max-width: 400px) {
  .cardDashboardCliente{
    width: 200%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 90px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 900 !important;
    margin-right: 0% !important;
  }
}
.cardDashboardMarcar{
  width: 170%;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 200px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
}
@media only screen and (max-width: 600px) {

  .cardDashboardMarcar{
    width: 170%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 100px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-top: 5% !important;
  }

  .cardsDivMarcar{
    display: flex;
    flex-direction:column;
    justify-content: center;

  
  }
  .cardDashboardMarcar3{
    width: 100%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 80px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }


  .cardFuturo{
    width: 80%;
    border-color: transparent !important;
    border-radius: 10px !important;
    height: 70px !important;
    cursor: pointer;
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 900 !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}

.cardDashboardMarcar2{
  width: 50vw;
  border-color: transparent !important;
  border-radius: 10px !important;
  height: 180px !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

@media only screen and (max-width: 800px) {
  .cardDashboardMarcar2{

    font-size: 14px !important;
  
  }
  

}
.cardDashboard2{
  width: 30%;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.cardDashboard3{
  width: 48%;
  margin-left: 2% !important;
  margin-right:1% !important;
}


.gridcenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.h5Detalhes{
  padding-top: 15px;
  padding-bottom: 5px;
}

.detalhesTexto{
  color: #333333;
}

.css-mjpk2e-MuiButtonBase-root-MuiStepButton-root{
  box-shadow: none !important;
}

.buttonBack {
  width: 100%;
  padding-left: 5%;
  text-align: left;
  padding-bottom: 20px;
}

.buttonNext {
  width: 100%;
  padding-right: 5%;
  text-align: right;
  padding-bottom: 20px;
}

.shadow__btn {
  padding: 20px 10px;
  border: none;
  font-size: 12px;
  color: #fff;
  border-radius: 20px;
  font-weight: 700;
  transition: 0.5s;
  transition-property: box-shadow;
  background: rgb(48,101,172);
  box-shadow: 0 0 10px rgb(48,101,172);
}







.calendario2{

  width: 80% !important;

}
.calendar2{

  width: 50%;
  margin-top: 5%;


}

.card-form-container {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.card-form {
  display: block;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 15px;
  transition: all 0.3s;
}
.card-form2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 15px;
  transition: all 0.3s;
  width:100vw
}

.expiry-and-cvc-container {
  display: flex;
}

.expiration-date-field {
  display: inline-block;
  flex: 1 1 auto;
  margin-right: 2%;
}

.cvc-field {
  display: inline-block;
  flex: 0 0 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.fc-list-week {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 310px;
  position: relative;
}
.fc-list-week::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.fc-list-week::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.fc-theme-standard td, .fc-theme-standard th{
border:0px !important;
margin:5px !important
}
 
/* Handle */
.fc-list-week::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.fc-list-week::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.separador{

  position: absolute;
width: 43px;
height: 2px;
left: 0px;
top: -130px;

background: rgba(255, 255, 255, 0.2);
border-radius: 0px 5px 5px 0px;
}

.separador2{

  position: absolute;
width: 43px;
height: 2px;
left: 0px;
top: -155px;

background: rgba(255, 255, 255, 0.2);
border-radius: 0px 5px 5px 0px;
}
.fc-list-table .fc-list-day .fc-day .fc-day-tue .fc-day-future .fc-list-day-text {
  border-radius: 10px !important;
  font-size: 15px;
  font-weight: bold;
  
}

.fc-list-week .fc-list-day-cushion {
  
  max-height: 300px !important; /* Ajuste a altura máxima conforme necessário */
}

.fc-media-screen{
  width:50vw!important;
min-width: 700px !important;
}



.fc-list-week .fc-list-day-text,.fc-list-week .fc-list-day-side-text {
  white-space: normal !important;
  overflow-y: auto !important;
}

.fc-list-day-cushion{
  background-color: transparent !important;
  overflow: auto !important;

}

.fc-list-table .fc-event {
  border-collapse: separate !important;
  border:2px solid !important;
  border-color: hsla(0,0%,82%,.3) !important;
}

.fc-list-table tbody th {
background-color: transparent !important;
  border-radius: 10px !important;
  overflow: scroll !important;
}

.fc {
 margin-top: 10px;
}

.fc-list-day-text{
  text-decoration: none;
  color: black;
  font-size: 12px;
}


.fc-list-day-text:hover{
  color:black ;
}


.fc-list-day-side-text{
  text-decoration: none;
  color: #333;
  font-size: 13px;
  
}

.fc-list-day-side-text:hover{
  color: black;
}
.fc-listWeek-view, .fc-view, .fc-list, .fc-list-sticky {
    border-color: transparent !important;

}
.fc .fc-toolbar-title {
 
  justify-content: center;
  display:flex;
  align-items: center;

}
.fc-toolbar-title,.fc-toolbar-chunk{
  color:#171E24  ;
  font-size: 8px;
  font-weight:400;
  
}

.fc-col-header-cell-cushion,.fc-daygrid-day-number{
  text-decoration: none;
  color:#171E24  ;
  font-size:10px

}
.fc-timegrid-slots, .fc-time {
  font-size: 12px; /* Ajuste o tamanho da fonte conforme necessário */
}

/* Diminuir o tamanho da fonte das horas no timeGridDay */
.fc-col-header-cell,.fc-time {
  font-size: 12px; /* Ajuste o tamanho da fonte conforme necessário */
}

.fc-header-toolbar{
  margin-bottom: 1 !important;
  justify-content: space-between !important;
  display: flex !important;
  align-items: center !important;
  

}

.fc-event-container .fc-event { 
      padding: 10px !important;
      
 }

.fc-event,.fc-event-start,.fc-event-end,.fc-event-past,.fc-list-event {
  background-color: rgba(255, 255, 255, 0.4) ;
  /* background-color: transparent; */
  border-radius: 10px !important;
  border-style: hidden;
  font-size: 13px;
max-height: 30px !important;
  align-items: center;
;
}

.fc-event{
  border-radius: 10px !important;
 max-height: 30px !important;
}
.fc-list-event-title .fc-content {
  text-align: start;
  margin-right: 150px;
  width:190px;
  
 
}

.fc-list-table .fc-event {
  border-collapse: separate !important;
  border: 0px solid !important;
  border-color: transparent !important;
}

 .fc-list-event-dot {
  width: 0px !important;
  height: 0px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 150px;
  border-width: 9px;
  

}



@media screen and (max-width: 800px) {
  .fc-media-screen{
   
  width: 30vw !important;
  min-width: none;
  }
  

  .fc-event {
    width:30vw!important;
  }

.fc .fc-media-screen .fc-direction-ltr .fc-theme-standard{
  width:30vw !important;
  float:none;

}
.fc .fc-list-table{
  width:auto;
}
.fc-header-toolbar{
  width:30vw !important;
  padding-right: 90vw !important;


}
.fc-list-table .fc-list-event-dot{
  margin-left:0px
}

.fc-list-event-title .fc-content{
  margin-right:0px;
}

.titleMarcar{
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  
  
  }


.titulo{
  font-size: 15px;
  margin-bottom: 10px;
}

.subtitulo{
  font-size: 13px;
  
  color: grey;
}

.cardspool2{

  font-size: 11px;


}

 .cardpiscina .cardspool{

  font-size: 10px;


}

.cardpiscina .pageIconPool {
  font-size: 10px;
  
}

.cardpiscina:hover {
  background-color: rgba(255, 255, 255, 0.6);
  width: 160px;
}


}

@media (max-width: 350px) {
  /* Ajustes para telas muito pequenas */
  .cardpiscina {
    width: 100%;
    margin: 60px 5px 5px 5px;

  }
  
  .cardpiscina:hover {
    background-color: rgba(255, 255, 255, 0.6);
    width: 120%;
  }

  .cardpiscina .cardspool{

    font-size: 7px;
  
  
  }
  
}



 .fc-prev-button, .fc-next-button,.fc-today-button, .fc-button  {
  /* Estilos personalizados para o botão prev */
  background-color: #0A56FA
  !important;
  border-radius: 5px !important;
  border-color: transparent !important;
  margin: 2px !important;
}
.fc-toolbar-chunk {

  min-width: 190px !important ;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fc-today-button  {
  /* Estilos personalizados para o botão prev */
  background-color: #0A56FA
  !important;
  width: 57px;
  height: 20px;
  font-family: 'Lexend';
font-style: normal;
font-weight: 500;
font-size: 12px !important;
line-height: 0px !important;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
  border-radius: 5px !important;
  border-color: transparent !important;
}
.fc-list-event-time {
  border-top-left-radius: 6px ;
  border-bottom-left-radius: 6px;
  border-left: 6px solid #000; /* Cor e espessura da borda no lado esquerdo */
}




.fc-list-event-title {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 6px solid #000;
}

.fc-list-week .fc-list-table {
  display: flex !important;
  flex-direction: column !important;
  
}

.fc-list-week .fc-list-day-side {
  width: 100%;
}

.fc-list-week .fc-list-day-cushion {
  overflow: auto !important;
  max-height: 300px !important; 
}



.fc-list-week .fc-list-day {
  position: relative !important;
  z-index: 1 !important;
}

@media screen and (max-width: 800px) {
  .fc-list-week {
    max-height: none !important; 
    overflow: visible !important; 
  }

  .fc-list-table tbody th {
    width:30vw !important
  }
  .fc-toolbar-chunk {

    
    display: flex;
    justify-content: flex-start;
  }

  button.fc-prev-button.fc-button.fc-button-primary {
    margin-left: 5vw !important;
}
  
}

.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.calendar-wrapper {
  width: 100%;

  
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .calendar-wrapper {
    padding: 0 0vw;
  }
  .calendar-container {
  width:350px
   
  }
}

.fc {
  margin-top: 10px;
}


.calendar {
  width: 90%;
  max-width: 800px;
  height: 50vh;
  font-size: 15px !important;
  justify-content: center;
  align-items: center;
 display: inline-block;
}

@media only screen and (max-width: 800px) {
  
  .calendar {
    height: 40vh;
 
  }
  
  .cardsDivDashboard {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0% !important;
    padding-left: 0%;
   padding-right: 0%;
  }

  .cardsDivDashboard2 {
    width: 100%;
    margin-top: 0% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0%;
    padding-right: 0%;
  
  }
  
  .cardDashboardCliente {
    width: 100%;
    margin-bottom: 16px !important;
  }

}

.cardDashboardResumo{
  width: 100%;
  border-radius: 10px !important;
  height: auto;
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important; 
  background-color: transparent !important;
  flex-direction: column;
}
.cardListaPagamentos{
  margin: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 60vw;
  height: 20vh;
  border-radius: 10px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  position: "relative"
}
.resumoOpcoes{
  margin-top: 15px;
  text-transform: initial;
  text-align: left;
}

.spanOpcoes{
  font-weight: 500;
  text-align: right;
}

.resumoCard1{
  width: 100%;
}

.teste{
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.multibanco{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.spanMb{
  font-weight: normal;
}

.divCardsPrecos{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.btnMarcacoes {
  margin-top: 20px !important;
  width: 270px;
  height: 40px;
  background-image: url("images/btnMarcacoes.svg");
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  text-transform: capitalize;
  color: #FFFFFF !important;
}


.dashboardTitle{
  margin-top: 12% !important;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboardTitleA{
  margin-top: 6% !important;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboardTitle2{
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboardTitle3{
  
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}


@media only screen and (max-width: 1080px) {

  
  .dashboardTitleA{
    margin-top: 10% !important;
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {

  .dashboardTitle{
    margin-top: 25% !important;
    font-size: 20px;
  }
  .dashboardTitleA{
    margin-top: 25% !important;
    font-size: 20px;
  }
  
  
  .dashboardTitle3{
    margin-top: 20% !important;
    font-size: 20px;
  }
  
  .dashboardTitle2{
    margin-top: 10% !important;
    font-size: 20px;
  }

  .fc-toolbar-title,.fc-toolbar-chunk{
    font-size: 8px;
    
  }
  .marcar{
    margin-top: 15%;
  }
}

@media only screen and (max-width: 400px) {
  .marcar{
    margin-top: 25%;
  }

}

@media only screen and (max-width: 300px) {
 
  .dashboardTitle2{
    font-size: 14px;
    max-width: 200px !important;
  }
  .DayPicker2 {
    flex-direction: column;

  margin-top: 5% !important;
  
  }

}




@media (max-width: 800px) {
  .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-input {
    font-size: 12px !important;
  }
}

@media (min-width: 800px) {
  .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-input {
    font-size: 16px !important;
  }
}

@media (max-width: 800px) {
  .MuiDialogContent-root p {
    font-size: 14px;
  }
  .footerletra{
    font-size: 10px;
  }
  .MuiTypography-root {
    font-size: 12px !important;
  }

  .multibanco{
    font-size: 12px;
  }

}
#rssBlock{
  width:100%;
  overflow: hidden;
}

/*remove p*/
.cnnContents {
  width:60%;
  margin:0 ;
  font-size: 10px;
  white-space: nowrap;
    color: #fff;
  font-weight: 500;
}
.marqueeStyle {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 30s linear infinite;
  animation: scrolling-left1 30s linear infinite;
  
}

.marqueeStyle2 {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 30s linear infinite;
  animation: scrolling-left2 30s linear infinite;
  
  
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
    0% {transform: translateX(0%);
        -webkit-transform: translateX(100%);}
      100% {transform: translateX(-100%);
          -webkit-transform: translateX(-200%);}
}
@keyframes scrolling-left2 {
    0% {transform: translateX(0%);
        -webkit-transform: translateX(-200%);}
      100% {transform: translateX(-100%);
          -webkit-transform: translateX(-200%);}
}

@-webkit-keyframes scrolling-left1 {
    0% {-webkit-transform: translateX(200%);}
      100% {-webkit-transform: translateX(-200%);}
}
@-webkit-keyframes scrolling-left2 {
    0% {-webkit-transform: translateX(200%);}
      100% {-webkit-transform: translateX(-200%);}
}


.ajuda{
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.fc-cancelado {
  position: absolute;
  right: 0;
  margin-right: 10px;
  color: white;
  padding: 1px 2px;
  border-radius: 5px !important;
}

.fc-filho {
  left: 0;
  color: white;
  padding: 1px 2px;
  border-radius: 5px;
}




.fc-cancelado2 {
    position: fixed;
    bottom: 33%;
    right: 50%;
    transform: translateX(50%);
    color: red;
    padding: 2px 5px;
    border-radius: 5px;
}
  
.transparentButton {
  background-color: transparent !important;
  
  color: black !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  box-shadow: none !important;
  margin-top: 20px !important;
 
}


.inverted-button {
  background-color: white !important;
  color: #1876d1 !important;
 
}

.event-pointer {
cursor:pointer;  
color: #000 !important;
}

.MuiPopover-paper {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.smallListItemButton {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  
}


.cardPiscinasMarcar{
  
  background: linear-gradient(180deg, rgba(23, 30, 36, 0.00) 43.23%, rgba(23, 30, 36, 0.90) 100%);
  background-blend-mode: multiply;
height: 20vh;
flex-shrink: 0;
}



.cardPiscinasMarcarNotSelected{
  height: 20vh !important;
  border-radius: 10px !important;
  cursor: pointer;
  background-blend-mode: multiply;
  flex-shrink: 0;
  background-image: url("images/carnotselected.svg");
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
box-shadow: none !important;
}

.cardUserNotSelected{
  /* position: absolute; */
  
  border-radius: 10px !important;
  cursor: pointer;
  
  background-image: url("images/cardimagemnotselected.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
box-shadow: none !important;
}
.cardUser{
    /* position: absolute; */
    transition: all 2s ease-in-out;
    
    border-radius: 10px !important;
    cursor: pointer;
    background-image: url("images/cardimagem.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
      flex-direction: row;
  display: flex;
  box-shadow: none !important;
}
.cardAulasNotSelected{
  position: absolute;

  border-radius: 10px !important;
  cursor: pointer;
  background-image: url("images/btnaulasnotselected.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
text-transform: capitalize !important;
color:#171E24 !important;
opacity: 40%;
}
.cardAulasNotSelected2{
  position: absolute;
 
  border-radius: 10px !important;
  cursor: pointer;
  background-image: url("images/btnnadolivrenotselected.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
text-transform: capitalize !important;
color:#171E24 !important;
opacity: 40%;
}
.cardAulas{
  position: absolute;

  border-radius: 10px !important;
  cursor: pointer;
  background-image: url("images/btnAulas.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
text-transform: capitalize !important;
color:#0A56FA !important;
}
.btnCancelado{
  color: red !important;
  padding: 2px 5px;
  border-radius: 5px;
}
.cardBanhos{
  position: absolute;
 
  border-radius: 10px !important;
  cursor: pointer;
  background-image: url("images/btnBanhos.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
    flex-direction: row;
display: flex;
text-transform: capitalize !important;
color:#0A56FA !important

}

.btnsMarcar{


    width: 250px;
    height: 100px;

    border-radius: 10px !important;
    cursor: pointer;
    background-image: url("images/cardGrids.svg");
    background-size: fill;
    background-repeat: no-repeat;
    background-position: center ;
    text-transform: capitalize !important;
      flex-direction: row;
      color:#0A56FA !important

}

.cardMinhasPiscinas {
 
  width: 250px;
  height: 283px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0A56FA;
  border-radius: 10px;
  background-image: url('images/cardMinhasPiscinas.svg');
  background-color:"transparent" !important;
   border-radius:10px !important;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;

}

.cardExplorarPiscinas {
 
  width: 250px;
  height: 283px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0A56FA;
  border-radius: 10px;
  border-radius:10px !important;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;

}

.cardModalidades {
 
  width: 119px;
  height: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0A56FA;
  border-radius: 10px;
  background-image: url('images/cardModalidades.svg');
  background-color:"transparent" !important;
   border-radius:10px !important;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;

}

.infobtn{

box-sizing: border-box;
width: 75px;
height: 22px;
background: #0A56FA;
border-radius: 6px;
border:0px;
font-family: 'Lexend Deca';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 22px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;



}
.infoPiscinas{

  background-image: url('images/infoPiscinas.svg');
  background-color:"transparent" !important;
   border-radius:10px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
}

.dialog-enter {
  opacity: 0;
  transform: scale(0.5);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}




.divDashboard {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 21.8vh;


  border-radius: 10px;
  margin: 5px;
}

.divDashboard2 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 35vh; 
  width: 50vw; 
  border-radius: 10px;
 margin:5px
}

.divDashboard3 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 45vh; 
width:100%;
  border-radius: 10px;
margin:5px
}

.divDashboard4 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 65vh; 
  width: 50vw; 
  border-radius: 10px;
 margin:5px
}

.divDashboard4 ul li {
  font-size: 14px;
}
@media (max-width: 800px){
 .css-4l501m {
    height: 70vh !important;
}}
@media (max-width: 800px) {
  .divDashboard4 ul li {
    font-size: 10px;
  }

  .divDashboard7 ul li {
    font-size: 12px;
  }
}

.divDashboard5 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 35vh;


  border-radius: 10px;
  margin: 5px;
  margin-inline: 20px;
}

.divDashboard6 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 55vh;
width: 100%;

  border-radius: 10px;
  margin: 5px;
  margin-inline: 20px;

}


.divDashboard7 {
  background-color: rgba(255, 255, 255, 0.6); /* Cor branca com opacidade 60% */
  height: 40vh;


  border-radius: 10px;
  margin: 10px;
  margin-inline: 20px;
}
.swiper {
  width: 90vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

}
@media (min-width: 800px) {
  .swiper {
    display: none;
  }
}

@media (max-width: 800px) {
  .formPageSA {
    display: none;
  }
}







.swiper-button-next,
.swiper-button-prev {
  width: 10px !important;
  height: 15px !important;
  margin-inline: -1vw !important;
  padding-bottom: 6vh !important;
  line-height: 0 !important;
}

/* Ícone menor dentro dos botões */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 15px !important;
  padding-bottom: 2vh !important;
  line-height: 0 !important;
}


@media (max-width: 800px) {
.cardDashboardMarcarB {
  width: 343px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 94px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 19px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}

.cardDashboardMarcarB2 {
  width: 165px !important;
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-radius: 10px !important;
  height: 93px !important;
  cursor: pointer;
  font-size: 20 !important;
  font-weight: 900 !important;
  margin-right: 1px !important;
  margin-left: 1px !important;
  margin-top: 8px !important;
  /* border: 1.5px solid rgba(255, 255, 255, 0.4); */
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  overflow: hidden;
  text-transform: initial !important;
  box-shadow: none !important;

}


.cardModalidades {
 
  width: 100px;
  height: 81px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0A56FA;
  border-radius: 10px;
  background-image: url('images/cardModalidades.svg');
  background-color:"transparent" !important;
   border-radius:10px !important;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center ;


}
.listPages {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #111111;
  overflow: auto;
  /* height: 100vh; */
  flex-direction: column;
  background-color: #dadeeb !important;
  background-image: url("images/Fundo Dashboard Cliente.png");
  background-size: 80%;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 105;
  transition: padding-left 0.5s ease;
  margin-left: 0px;
  transition: margin-left 0.5s ease;
  border-radius: 10px;
}
}
